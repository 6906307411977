import {required, min, confirmed, length, email, integer} from "vee-validate/dist/rules"
import {extend} from "vee-validate"
// import { setInteractionMode } from 'vee-validate';

// setInteractionMode('eager');



extend("required", {
    ...required,
    message: "Dit veld is verplicht"
})

extend("email", {
    ...email,
    message: "Voer een geldig emailadres in"
})

extend("confirmed", {
    ...confirmed,
    message: "De velden komen niet overeen"
})

extend("length", {
    ...length,
    message: "Het veld {_field_} moet precies {length} karakters bevatten"
})

extend("min", {
    ...min,
    message: "Het veld {_field_} moet minimaal {length} karakters bevatten"
})

extend("integer", {
    ...integer,
    message: "Voer enkel nummers in"
})
