<template>
  <div class="container">
    <Auctionloader v-if="startAuction" />
    <div class="column is-9">
      <h2 class="subtitle has-text-light">
        Registratieoverzicht
      </h2>
      <!-- {{ allCoverages }} -->
      <h1 class="title has-text-light">
        Check nog één keer jouw situatie en start de veiling!
      </h1> 
      <Cardslot> 
        <!-- <template #iconheader> 
          <b-icon
            icon="gavel"
            size="is-large"
            type="is-light"
          />
        </template> -->

        <template #header>
          {{ product.name }}
        </template>
        <!-- <template #headertag /> -->
        <template
          #content
        >
          <!-- OPSTAL EN INBOEDEL -->
          <h1
            v-if="product.name == 'Inboedelverzekering' || product.name == 'Opstalverzekering' || product.name == 'Autoverzekering'"
            class="subtitle has-text-light"
          >
            Mijn situatie
          </h1>
          <div
            v-if="product.name == 'Inboedelverzekering' || product.name == 'Opstalverzekering' "
            class="columns is-vcentered"
          >
            <div
              v-if="imageID"
              class="column has-text-centered"
            >
              <Imageloader
                v-if="imageloading"
              />
              <div
                v-else-if="houseinfodata.houseinfo.image"
                class="box"
              >
                <b-image
                  :src="houseImage"
                  ratio="2by1"
                />
              </div>
            </div>
            <div class="column">
              <div
                v-for="(value, name) in relevanthouseinfodata"
                :key="name"
                class="has-text-light"
              >
                <div class="columns">
                  <div class="column has-text-left">
                    {{ name }}:
                  </div>
                  <div class="column has-text-right">
                    {{ value }}
                  </div>
                </div>
              </div> 
            </div>
          </div>
          <!-- AUTOVERZEKERING -->
          <div
            v-if="product.name == 'Autoverzekering'"
            class="box"
          >
            <!-- <div class="column has-text-centered">
              <div
                v-if="imageID"
              >
                <Imageloader
                  v-if="imageloading"
                />
                <div
                  v-else
                  class="box"
                >
                  <img
                    src="../../assets/FORD.png"
                  >
                </div>
              </div>
            </div> -->
            
            <div
              v-for="(value, name) in relevantcardata"
              :key="name"
              class="has-text-light"
            >
              <div class="columns">
                <div class="column has-text-left ">
                  {{ name }}:
                </div>
                <div class="column has-text-right">
                  {{ value }}
                </div>
              </div>
            </div>
          </div>
          <h1
            v-if="choice"
            class="subtitle has-text-left has-text-light"
          >
            Mijn keuze
          </h1>
          <Cardslot v-if="choice">
            <template #header>
              {{ choice.name }}
            </template>
            <template #content>
              <div class="has-text-centered">
                <b-tooltip
                  :label="choice.description"
                  multilined
                >
                  <b-icon
                    size="is-large"
                    type="is-light"
                    :icon="choice.icon"
                  />
                </b-tooltip>
              </div>
            </template>
          </Cardslot>
          <!-- <br> -->
          <h1
            v-if="options"
            class="subtitle has-text-left has-text-light"
          >
            Mijn dekking
          </h1>
          <div
            v-if="options"
            class="columns is-multilined"
          >
            <div
              v-for="coverage in options"
              :key="coverage.key"
              class="column"
            >
              <div class="box">
                <div class="column has-text-centered">
                  <b-tooltip :label="coverage.description">
                    <b-icon
                      size="is-large"
                      type="is-light"
                      :icon="coverage.icon"
                    />
                  </b-tooltip>
                </div>
                <div class="column has-text-centered has-text-light">
                  {{ coverage.name }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <b-button
            expanded
            label="Start de veiling!"
            type="is-primary"
            icon-left="gavel"
            @click="postRegistration(product.pid)"
          />
        </template>
      </Cardslot>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import Auctionloader from "../shared/auctionloader";
import Imageloader from "../shared/imageloader"
import Cardslot from "../shared/cardslot"


export default {
  name: "Applyrequest",
  components: {
    Auctionloader,
    Imageloader,
    Cardslot
  },
  inject: ["upwardRoute"],
  data() {
    return {
            houseImage: "",
            choice: "",
            options: "",
            // imageID: "",
            // carImage: "../../assets/ford.png"

      //  startAuction: true,
    };
  },
  computed: {
    ...mapGetters(["product", "houseinfodata", "startAuction", "imageloading", "relevanthouseinfodata", "relevantcardata", "regdata", "allCoverageChoices", "allCoverageOptions"]),
    imageID() {
      return this.houseinfodata.houseinfo.image;
    },
    carImage() {
      return "../../assets/" + this.relevantcardata.Merk + ".png"
    }

  },
  // updated: async function () {
  //   this.fetchImage();
  // },
  created: async function () {
    if(this.product.name == "Inboedelverzekering" || this.product.name == "Opstalverzekering")
    {await this.fetchImage()}
  
  },
  mounted() {
    if(this.product.coverageChoices) {
   this.choice = this.product.coverageChoices.filter((x) => this.allCoverageChoices.includes(x.key))[0]
   }
   if(this.product.coverageOptions) {
     this.options = this.product.coverageOptions.filter((x) => this.allCoverageOptions.includes(x.key))
   }
  },

  methods: {
    ...mapActions(["postRegistration", "toFirstRoute", "getProductsStatus", "getRegistrationstatusData", "getImage"]),
    async fetchImage() {
      if (!this.$store.state.image[this.imageID] && this.$store.state.image) {
        // console.log('ik kom hier')
        // console.log(this.$store.state.userdata.houseinfodata.houseinfo)
        this.houseImage = await this.getImage(this.imageID);
        // console.log(this.houseImage)
      } else {
        this.houseImage = this.$store.state.image[this.imageID];
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
