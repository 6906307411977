import axios from "axios"

const url = "/registration"

export default {
    state: {
        emailaddressSuccess: false
    },
    getters: {
        emailaddressSuccess(state) {
            return state.emailaddressSuccess
        }
    },
    mutations: {
        emailaddressSuccess(state, payload) {
            state.emailaddressSuccess = payload
        }
    },
    actions: {

        async putEmailaddressregistration(context, {step, registrationdata}) {

            const putData = {
                data: registrationdata
            }

            try {

                const response = await axios.put(url + "/" + step, putData)

                if(response.status == 200) {

                    if(step == "emailaddress") {
                        context.commit("emailaddressSuccess", true)
                    }

                    if (step == "emailaddressVerification" ) {
                        context.dispatch("commituser", response)
                        context.dispatch("getPersonaldata")
                        context.dispatch("getRegistrationstatusData")
                    }
                }
            }

            catch (error) {
                // define specific error handling actions here
            }
        }
    }
}
