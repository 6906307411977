<template
  v-if="isAuthenticated"
>
  <div>
    <div class="hero">
      <div class="hero-body">
        <h1 class="title has-text-light">
          Mijn account
        </h1>
        <div class="columns">
          <div class="column">
            <Cardslot> 
              <template #iconheader>
                <b-icon
                  icon="user"
                  size="is-large"
                  type="is-light"
                />
              </template>
              <template #header>
                Accountgegevens
              </template>
              <template #tagheader />
              <template #content>
                <div class="columns is-multiline">
                  <div
                    class="column"
                  >
                    <a href="">
                      <div
                        class="box mouseover"
                      >
                        <div class="column has-text-centered">
                          <b-icon
                            size="is-medium"
                            type="is-light"
                            icon="user-circle"
                          />
                          <p class="column has-text-centered has-text-light">
                            Wijzig je accountgegevens
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    v-if="!personalData.registration.steps.password"
                    class="column"
                  >
                    <a href="">
                      <div class="box mouseover">
                        <div class="column has-text-centered">
                          <b-icon
                            size="is-medium"
                            type="is-light"
                            icon="key"
                          />
                          <p class="column has-text-centered has-text-light">
                            Maak een wachtwoord aan
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <!-- <template v-if="personalData">
                  <b-button
                    v-if="!personalData.registration.steps.password"
                    size="is-small"
                    expanded
                    type="is-primary"
                    @click="isComponentModalActive = true"
                  >
                    Maak een wachtwoord aan
                  </b-button>
                </template> -->
                <!-- <b-modal
                  v-model="isComponentModalActive"
                  scroll="keep"
                >
                  <div class="cards">
                    <div class="card-content">
                      <div class="content has-text-centered">
                        <div class="columns has-text-centered is-multiline">
                          <div class="column has-text-centered is-12">
                            <h1 class="title has-text-light">
                              Wijzig hier je wachtwoord
                            </h1>
                          </div>
                          <div class="column has-text-centered">
                            <Passwordregistration />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-modal> -->
                <!-- <template v-if="personalData">
                  <b-button
                    v-if="personalData.registration.steps.password"
                    size="is-small"
                    expanded
                    type="is-primary"
                    @click="isComponentModalActive = true"
                  >
                    Wijzig je wachtwoord
                  </b-button>
                </template> -->
              </template>
              <template #footer />
            </Cardslot>
          </div>
          <div class="column">
            <Cardslot>
              <template #iconheader>
                <b-icon
                  icon="id-card"
                  size="is-large"
                  type="is-light"
                />
              </template>
              <template #header>
                Persoonsgegevens
              </template>
              <template #tagheader />
              <template #content>
                <div class="columns is-multiline">
                  <div class="column">
                    <router-link to="/preregistration/familyCompositionRegistration">
                      <div class="box mouseover">
                        <div class="column has-text-centered">
                          <b-icon
                            size="is-medium"
                            type="is-light"
                            icon="house-user"
                          />
                          <p class="column has-text-centered has-text-light">
                            Wijzig je situatie
                          </p>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <div class="column">
                    <a href="">
                      <div class="box mouseover">
                        <div class="column has-text-centered">
                          <b-icon
                            size="is-medium"
                            type="is-light"
                            icon="passport"
                          />
                          <p class="column has-text-centered has-text-light">
                            Wijzig je persoonsgegevens
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </template>
              <template #footer />
            </Cardslot>
          </div>
          <div class="column">
            <Cardslot>
              <template #iconheader>
                <b-icon
                  icon="user-check"
                  size="is-large"
                  type="is-light"
                />
              </template>
              <template #header>
                Verificatiestatus
              </template>
              <template #tagheader />
              <template #content>
                <div class="columns is-multiline">
                  <div class="column">
                    <div class="column has-text-centered">
                      <b-icon
                        size="is-medium"
                        type="is-success"
                        icon="check"
                      />
                      <p class="column has-text-centered has-text-light">
                        Account is succesvol geverifieerd
                      </p>
                    </div>
                  </div>
                </div>
                <!-- <div class="columns is-multiline">
                  <div class="column is-half">
                    <a href="">
                      <div class="box">
                        <div class="column has-text-centered">
                          <b-icon
                            icon="check"
                            size="is-large"
                            type="is-success"
                          />
                          <p class="column has-text-centered has-text-light">
                            Account is geverifieerd
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div> -->
                <!-- <b-button
                  v-if="showClientComponent"
                  expanded
                  label="Verifieer mijn account"
                  tag="router-link"
                  to="/registration/clientregistration"
                  type="is-primary"
                />
                <div
                  v-else
                  class="has-text-centered"
                >
                  <b-icon
                    pack="fas"
                    icon="check"
                    size="is-large"
                    type="is-success"
                  />
                  <p class="is-italic has-text-light">
                    Account is succesvol geverifieerd
                  </p>
                </div> -->
              </template>
              <template #footer />
            </Cardslot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
import { mapGetters, mapActions } from "vuex";
// import Passwordregistration from "../components/preregistration/passwordregistration";
import Cardslot from "../components/shared/cardslot";


export default {
  name: "Dashboard",
  components: {
    // Passwordregistration,
    Cardslot  },
  data() {
    return {
      showClientComponent: false,
      isComponentModalActive: false,
      hover: false
    };
  },
  computed: {
    ...mapGetters([
      // "requests",
      "uid",
      // "genericloader",
      "personalData",
      "stepsMapping",
      // "insuranceChoice",
      "stepsDone",
      "products",
      // "productsStatus",
    ]),
    // filteredRequests() {
    //  return  this.requests.filter((request) => {
    //             if((new Date(request.auctionEnd) - new Date()) < 0)
    //                   return request;
    //                 })
    // }
  },
  created: async function () {
    // await this.getRequests();
    // await this.getProductsStatus();
  },
  mounted: async function () {
    await this.getPersonaldata();
    // this.getRegistrationstatusData();
    //  await this.getProductsStatus()
  },
  methods: {
    ...mapActions([
      "getRequests",
      // "tryAutoLogin",
      "getPersonaldata",
      // "getRegistrationstatusData",
      "getProductsStatus",
      "setPid",
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

.box {
  height: 100%;
};

.cards {
  background-color: $primary;

}

/* .column {
    height: 90%
  } */

/* p {
    color: white
  } */

/* .child {
  height: max-content;
} */

/* .parent {
  display: flex
} */

hr {
  color: white;
  height: 1px;
  clear: both;
  display: block;
}

/* .columns:not(:last-child) {
  margin-bottom: -0.75rem;
} */

p {
  font-size: 0.8em;
}



// .opacity {
//   opacity: 0.3;
// }
</style>