<template>
  <div class="container">
    <!-- <a>
      <b-icon
        pack="fas"
        icon="chevron-up"
        size="is-large"
        type="is-light"
        @click.native="upwardRoute"
      />
    </a> -->
    <div class="column is-9">
      <h2 class="subtitle has-text-light">
        Persoonsverificatie
      </h2>
      <template
        v-if="
          stepsDone.passportApi &&
            stepsDone.passportUser &&
            !stepsDone.facecomparison
        "
      >
        <h1 class="title has-text-light">
          Maak hieronder een foto van jezelf zodat de verzekeraar zeker weet dat
          jij het bent
        </h1>
        <div class="buttons">
          <b-button
            :type="{
              'is-medium': true,
              'is-primary': true,
              'is-link': camAllowedFace,
            }"
            @click="allowCam(), (skipFaceStep = false)"
          >
            Ja, ik doe dit meteen
          </b-button>
          <b-button
            :type="{
              'is-medium': true,
              'is-primary': true,
              'is-link': skipFaceStep,
            }"
            @click.native="
              (skipFaceStep = true),
              (camAllowed = false),
              toNextRoute($route.path)
            "
          >
            Nee, ik doe dit later
          </b-button>
        </div>
        <div class="column is-half">
          <video
            v-show="camAllowedFace"
            id="video"
            ref="video"
            class="has-text-centered"
          />
          <canvas
            id="canvas"
            ref="canvas"
          />
          <b-button
            v-if="camAllowedFace"
            expanded
            icon-left="camera"
            type="is-primary"
            @click="captureface()"
          >
            Maak foto
          </b-button>
          <b-image
            v-if="stepsDone.facecomparison"
            :src="faceImage"
          />
        </div>
      </template>
      <template v-if="stepsDone.facecomparison">
        <h1 class="title has-text-light">
          Jouw account is succesvol geverifieerd
          <br>
          <br>
          <div class="column has-text-centered">
            <b-icon
              pack="fas"
              icon="check"
              size="is-large"
              type="is-success"
            />
            <br>
            <br>
            <b-tooltip
              size="is-small"
              label="Klik om door te gaan"
              always
              position="is-bottom"
            >
              <a>
                <b-icon
              
                  pack="fas"
                  icon="chevron-down"
                  type="is-light"
                  @click.native="toNextRoute($route.path)"
                />
                <!-- <p class="has-text-light">Ga naar de volgende stap</p>  -->
              </a>
            </b-tooltip>
          </div>
        </h1>
      </template>
    </div>
    <!-- <a>
      <b-icon
        pack="fas"
        icon="chevron-down"
        size="is-large"
        type="is-light"
        @click.native="downwardRoute"
      />
    </a> -->
  </div>
</template>

<script>
// import axios from 'axios'
// import {
//   required,
//   // minValue
// } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
// import Loader from "../loader";
// import { ValidationObserver } from "vee-validate";
// import BInputWithValidation from "../../components/inputs/BInputWithValidation";
// import BCheckboxesWithValidation from "../../components/inputs/BCheckboxesWithValidation";
// import BSelectWithValidation from "../../components/inputs/BSelectWithValidation";

// import countries from '../../assets/countries.js'
// import $ from "jquery";

export default {
  name: "Clientverification",
  components: {
    // ValidationObserver,
    // BInputWithValidation,
    // BCheckboxesWithValidation,
    // BSelectWithValidation,
    // Loader,
  },
  // inject: ["downwardRoute", "upwardRoute"],
  // beforeRouteEnter() {
  //   // this.toNextRoute(this.$route.path)
  // },
beforeRouteLeave(to, from, next) {
    this.stopCam();
    next();
  },
  data() {
    return {
      // selected: new Date(),
      mediaStream: null,
      camAllowedFace: false,
      video: {},
      canvas: {},
      uploadImage: [],
      passportImage: "",
      skipFaceStep: false,
      faceImage: "",
      uploadImageAllowed: false,
      // countries: countries, //from external countries file
    };
  },
  computed: {
    ...mapGetters(["stepsDone", "freeze"]),
  },
 watch: {
    freeze: function (freeze) {
      if (!freeze && this.camAllowedFace) {
        this.allowCam();
      }
    },

  },
  mounted: function () {
    // this.toNextRoute(this.$route.path)
    // this.getregistration();
  },
  methods: {
    ...mapActions(["updateregistration", "toNextRoute"]),
    allowCam() {
      // this.video = this.$refs.video;
      //  navigator.mediaDevices.getUserMedia({ video: true })
      //   .then(mediaStream => {
      //     this.mediaStream = mediaStream
      //     this.$refs.video.srcObject = mediaStream
      //     this.$refs.video.play()
      //     this.camAllowedFace = true
      //   })
      //   .catch(error => console.error('getUserMedia() error:', error))
      this.video = this.$refs.video;
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ audio: false, video: true })
          .then((stream) => {
            this.stream = stream;
            this.video.srcObject = stream;
            this.video.play();
            this.camAllowedFace = true;
          });
      }
    },
    stopCam() {
      if (this.stream != null) {
        const tracks = this.stream.getTracks();
        tracks.map((track) => track.stop());
      }
      // console.log(this.mediaStream)
      // const tracks = this.mediaStream.getTracks()
      // console.log(tracks)

      // this.video = this.$refs.video;
      // console.log(navigator.mediaDevices)
    },
    async captureface() {
      var canvas = this.$refs.canvas;
      // console.log(canvas.height);
      // console.log(canvas.width);
      // var maxW = 250
      // var maxH = 200;
      // var iw = this.video.width
      // var ih = this.video.height
      // var scale = Math.min(maxW / iw)
      // var iwScaled = iw * scale
      // var ihScaled = ih * scale
      canvas.width = 600;
      canvas.height = 450;

      var context = canvas.getContext("2d");
      context.drawImage(this.video, 0, 0, 600, 450);
      // this.sharpen(context, 600, 450, 1)
      this.stopCam()
      this.faceImage = canvas.toDataURL("image/png");
      this.updateregistration({
        step: "facecomparison",
        registrationdata: { image: this.faceImage },
        route: this.$route.path,
      });

      //  try {
      //    let update = await this.updateregistration({
      //     step: "facecomparison",
      //     registrationdata: { image: this.faceImage },
      //     route: this.$route.path
      //   });
      //   if(update.response == 200) {
      //     this.stopCam()
      //   }
      //  } catch (error) {
      //    console.log(error)
      //  }
    },
    ...mapActions(["getregistration"]),
  },
};
</script>

<style scoped>
#video-container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 5%;
  left: 5%;
  bottom: 5%;
  right: 5%;
  margin: 0;
  /* padding: 0.5em 0.5em 0.5em 2em; */
  border: 0.2em solid rgba(242, 47, 70, 0.8);
  /* background-image: url('../../assets/paspoort2.png');
  background-repeat: no-repeat; */
  /* opacity: 20%; */
  /* background: rgba(242, 47, 70, 0.8); */
  color: #fff;
  font-size: 16px;
  line-height: 1;
}

#video {
  background-color: #000000;
  border-width: 2px;
  border-style: solid;
  border-color: white;
  /* width: 50%;
  height: 100%; */
  z-index: 1;
}

#canvas {
  display: none;
}

.field.invalid input {
  border: 1px solid red;
  /* background-color: #ffc9aa; */
}

.grid.container {
  margin-top: 5em;
}

</style>
