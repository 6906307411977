var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"column is-9"},[_c('h2',{staticClass:"subtitle has-text-light"},[_vm._v(" Persoonsverificatie ")]),(
        _vm.stepsDone.passportApi &&
          _vm.stepsDone.passportUser &&
          !_vm.stepsDone.facecomparison
      )?[_c('h1',{staticClass:"title has-text-light"},[_vm._v(" Maak hieronder een foto van jezelf zodat de verzekeraar zeker weet dat jij het bent ")]),_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"type":{
            'is-medium': true,
            'is-primary': true,
            'is-link': _vm.camAllowedFace,
          }},on:{"click":function($event){_vm.allowCam(), (_vm.skipFaceStep = false)}}},[_vm._v(" Ja, ik doe dit meteen ")]),_c('b-button',{attrs:{"type":{
            'is-medium': true,
            'is-primary': true,
            'is-link': _vm.skipFaceStep,
          }},nativeOn:{"click":function($event){(_vm.skipFaceStep = true),
            (_vm.camAllowed = false),
            _vm.toNextRoute(_vm.$route.path)}}},[_vm._v(" Nee, ik doe dit later ")])],1),_c('div',{staticClass:"column is-half"},[_c('video',{directives:[{name:"show",rawName:"v-show",value:(_vm.camAllowedFace),expression:"camAllowedFace"}],ref:"video",staticClass:"has-text-centered",attrs:{"id":"video"}}),_c('canvas',{ref:"canvas",attrs:{"id":"canvas"}}),(_vm.camAllowedFace)?_c('b-button',{attrs:{"expanded":"","icon-left":"camera","type":"is-primary"},on:{"click":function($event){return _vm.captureface()}}},[_vm._v(" Maak foto ")]):_vm._e(),(_vm.stepsDone.facecomparison)?_c('b-image',{attrs:{"src":_vm.faceImage}}):_vm._e()],1)]:_vm._e(),(_vm.stepsDone.facecomparison)?[_c('h1',{staticClass:"title has-text-light"},[_vm._v(" Jouw account is succesvol geverifieerd "),_c('br'),_c('br'),_c('div',{staticClass:"column has-text-centered"},[_c('b-icon',{attrs:{"pack":"fas","icon":"check","size":"is-large","type":"is-success"}}),_c('br'),_c('br'),_c('b-tooltip',{attrs:{"size":"is-small","label":"Klik om door te gaan","always":"","position":"is-bottom"}},[_c('a',[_c('b-icon',{attrs:{"pack":"fas","icon":"chevron-down","type":"is-light"},nativeOn:{"click":function($event){return _vm.toNextRoute(_vm.$route.path)}}})],1)])],1)])]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }