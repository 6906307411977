<template>
  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <div class="column is-half is-offset-one-quarter">
          <!-- <b-message
            v-if="errormessage"
            title="Oeps, er lijkt iets misgegaan!"
            size="is-small"
            aria-close-label="Close message"
          >
            {{ this.errormessage }}
          </b-message> -->

          <h1 class="title has-text-light has-text-centered">
            Log-in
          </h1>
          <h2 class="subtitle has-text-light has-text-centered">
            met je wachtwoord
          </h2>
          <!-- <p class="title has-text-centered is-light">Log-in met wachtwoord</p> -->

          <form
            class="box"
            @submit.prevent="postLogin(userData)"
          >
            <ValidationObserver
              ref="observer"
              v-slot="{ invalid }"
            >
              <BInputWithValidation
                v-model.trim="userData.emailaddress"
                rules="required|email"
                type="email"
                label="Email"
                icon="envelope"
                vid="any"
              />
              <BInputWithValidation
                v-model.trim="userData.password"
                rules="required"
                type="password"
                label="Wachtwoord"
                icon="key"
                password-reveal
                vid="any"
              />

              <b-button
                icon-left="sign-in-alt"
                :disabled="invalid"
                class="is-link"
                native-type="submit"
              >
                Log in
              </b-button>
            </ValidationObserver>
          </form>
          <!-- <h1 class="title has-text-light has-text-centered">Log-in</h1> -->
          <h2 class="subtitle has-text-light has-text-centered">
            Of met een verificatiecode
          </h2>

          <form
            class="box"
            @submit.prevent="postEmailaddress(userData)"
          >
            <!-- <p class="title has-text-centered">Log-in met verificatiecode</p> -->
            <ValidationObserver
              ref="observer"
              v-slot="{ invalid }"
            >
              <BInputWithValidation
                v-model.trim="userData.emailaddress"
                rules="required|email"
                type="email"
                label="Email"
                icon="envelope"
                buttontext="Verzenden"
                buttonicon="paper-plane"
                expanded-input
                vid="any"
              />
              <form
                v-if="emailaddressVerification"
                @submit.prevent="postTokenVerification(userData)"
              >
                <BInputWithValidation
                  v-model.trim="userData.verificationToken"
                  rules="required"
                  type="input"
                  label="Verificatiecode"
                  icon="check"
                  vid="any"
                />

                <b-button
                  :disabled="invalid"
                  class="is-link"
                  native-type="submit"
                  icon-left="sign-in-alt"
                >
                  Log in
                </b-button>
              </form>
            </ValidationObserver>
          </form>
        </div>
      </div>
      <!-- <Loader v-if="genericloader" /> -->
    </div>
  </section>
</template>

<script>
// import axios from 'axios'
import { mapActions, mapGetters } from "vuex";
// import Loader from "../components/shared/loader.vue";
import { ValidationObserver } from "vee-validate";
import BInputWithValidation from "../components/inputs/BInputWithValidation";
// import BExpandedInputWithValidation from "../components/inputs/BExpandedInputWithValidation";
// import BInputWithValidationNoFB from "../components/inputs/BInputWithValidationNoFB";

export default {
  components: {
    ValidationObserver,
    // Loader,
    BInputWithValidation,
    // BExpandedInputWithValidation,
    // BInputWithValidationNoFB,
  },
  data() {
    return {
      userData: {
        emailaddress: "",
        password: "",
        verificationToken: "",
      },
      isSubmitted: false,
    };
  },
  computed: {
    ...mapGetters([
      "emailaddressVerification",
      "genericloader",
      "errormessage",
    ]),
  },
  watch: {
    errormessage() {
      this.$refs.observer.setErrors({
        any: [this.errormessage],
      });
    },
  },
  created: function () {
    // this.$store.state.errormessage = false;
  },
  methods: {
    ...mapActions(["postLogin", "postEmailaddress", "postTokenVerification"]),
  },
};
</script>

<style>
</style>
