<template>
  <div class="container">
    <div class="column">
      <h2 class="subtitle has-text-light">
        Dekking
      </h2>
      <template v-if="product.coverageOptions">
        <h1 class="title has-text-light">
          Waar wil je graag voor verzekerd zijn?
        </h1>
        <!-- {{ checkedCoverage }} -->
        <div class="columns is-multilined">
          <div
            v-for="coverage in coverageOptions"
            :key="coverage.key"
            class="column"
          >
            <a @click="coverage.checked = !coverage.checked">
              <div
                class="box mouseover"
                :class="{ green: coverage.checked }"
              >
                <div class="column has-text-centered">
                  <b-tooltip :label="coverage.description">
                    <b-icon
                      size="is-large"
                      type="is-light"
                      :icon="coverage.icon"
                    />
                  </b-tooltip>
                </div>
                <div class="column has-text-centered has-text-light">
                  {{ coverage.name }}
                </div>
                <br>
                <div class="column has-text-centered">
                  <b-switch
                    v-model="coverage.checked"
                    class="has-text-light"
                    type="is-green"
                    passive-type="is-primary"
                    size="is-small"
                  >
                    <p v-if="!coverage.checked">Nee, liever niet</p>
                    <p v-else>Ja, graag!</p>
                  </b-switch>
                </div>
              </div>
            </a>
          </div>
        </div>
        <!-- <div class="columns is-multiline">
          <div
            v-for="coverage in coverageOptions"
            :key="coverage.key"
            class="column"
          >

            <a @click="coverage.checked = !coverage.checked">
              <Cardslot :class="{ green: coverage.checked }">

                <template #header>
                  {{ coverage.name }}
                </template>
                <template #content>
                  <div class="has-text-centered">
                    <b-tooltip :label="coverage.description">
                      <b-icon
                        size="is-large"
                        type="is-light"
                        :icon="coverage.icon"
                      />
                    </b-tooltip>
                  </div>
                </template>
                <template #footer>
                  <div class="has-text-centered">

                    <b-switch
                      v-model="coverage.checked"
                      class="has-text-light"
                      type="is-green"
                      passive-type="is-primary"
                      size="is-small"
                    >
                      <p v-if="!coverage.checked">
                        Nee, liever niet
                      </p>
                      <p v-else>
                        Ja, graag!
                      </p>
                    </b-switch>
                  </div>
                </template>
              </Cardslot>
            </a>
          </div>
        </div> -->

        <a
          v-if="filteredOptions && filteredOptions[0]"
          @click="
            updateregistration({
              step: step,
              registrationdata: {
                coverageOptions: filteredOptions,
              },
              route: $route.path,
            })
          "
        >
          <b-icon
            pack="fas"
            icon="chevron-down"
            size="is-large"
            type="is-light"
          />
          <span class="has-text-light">Dit zijn mijn keuzes</span>
          <!-- <p class="has-text-light">Ga naar de volgende stap</p>  -->
        </a>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import Cardslot from "../shared/cardslot.vue";

export default {
  components: {
    // Cardslot,
  },
  data() {
    return {
      coverageOptions: "",
    };
  },
  computed: {
    ...mapGetters(["product", "allCoverageOptions"]),
    filteredOptions() {
      if (this.coverageOptions) {
        var options = this.coverageOptions
          .filter((coverage) => coverage.checked)
          .map((x) => x.key);
      }
      return options;
    },
    step() {
      const product = this.product.key.split("Insurance")[0];
      return `${product}CoverageOptions`;
    },
  },
  mounted() {
    //// map checked on coverageoptions
    const options = this.product.coverageOptions;

    if (options) {
      this.coverageOptions = options.map((x) => {
        return this.allCoverageOptions &&
          this.allCoverageOptions.indexOf(x.key) > -1
          ? { ...x, checked: true }
          : { ...x, checked: false };
      });
      if (!this.coverageOptions.filter((x) => x.checked)[0]) {
       this.coverageOptions = this.coverageOptions.map((x) => {
          x.checked = x.advice;
          return x;
        });
      }
    }
  },
  methods: {
    ...mapActions(["updateregistration", "toNextRoute", "getProductsStatus"]),
    // ...mapMutations(["updateAllCoverages"])
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";

.box {
  height: 100%;
}

.green {
  border-width: 1px;
  border-style: solid;
  border-color: $green;
}

// .card {
//   cursor: pointer;
// }
</style>

