import axios from "axios"
import router from "../router"

export default {
    // namespaced: true,
    state: {
        products: "",
        errormessage: "",
        uid: null,
        genericloader: false,
        emailaddressVerification: "",
        // isAuthenticated: false,,
        stepsMapping: {
            emailaddress: "/preregistration/emailregistration",
            emailaddressVerification: "/preregistration/emailregistration",
            password: "/registration/emailregistration",
            insuranceChoice: "/preregistration/transactionregistration",
            houseinfoApi: "/registration/propertyregistration",
            houseinfoUser: "/registration/propertyregistration",
            passportApi: "/registration/clientregistration",
            passportUser: "/registration/clientregistration",
            facecomparison: "/registration/clientverification",
            licenseplate: "/registration/licenseplateregistration",
            travelCoverageOptions: "/registration/coverageOptionsregistration",
            travelCoverageChoice: "/registration/coverageChoiceregistration",
            contentsCoverageOptions: "/registration/coverageOptionsregistration",
            homeCoverageOptions: "/registration/coverageOptionsregistration",
            carCoverageChoice: "/registration/coverageChoiceregistration",
        },
        preRegistrationRoutes: ["/preregistration/emailregistration", "/preregistration/familyCompositionregistration", "/dashboard"],
        environment: process.env.NODE_ENV
    },
    getters: {
        isAuthenticated(state) {
            return !!state.uid
        },
        genericloader(state) {
            return state.genericloader
        },
        errormessage(state) {
            return state.errormessage
        },
        uid(state) {
            return state.uid
        },
        products(state) {
            return state.products
        },
        emailaddressVerification(state) {
            return state.emailaddressVerification
        },
        stepsMapping(state) {
            return state.stepsmapping
        },
        preRegistrationRoutes(state) {
            return state.preRegistrationRoutes
        },
        isDevelopment(state) {
            if(state.environment == "development") {
                return true
            }
        },
        isProduction(state) {
            if(state.environment == "production") {
                return true
            }
        }
    },
    mutations: {

        // changeState: function (state, changed) {
        //   Object.entries(changed).forEach(([name, value]) => {
        //     state[name] = value
        //   })
        // },

        authUser(state, payload) {
            // console.log(this.state.idToken)
            state.uid = payload.uid
            // state.emailaddress = payload.emailaddress
            // state.firstname = userData.firstname,
            // state.lastname = userData.lastname
            // state.offers = userData.offers
            // state.userId = userData.userId
            // console.log(this.state.idToken)
        },

        clearAuthData(state) {
            state.uid = null
        },

        clearerrormessage(state) {
            state.errormessage = null
        },
        saveProducts(state, payload) {
            state.products = payload
        },

        toggleLoader(state, payload) {
            state.genericloader = payload
        },

        updateErrormessage(state, payload) {
            state.errormessage = payload
        },
        updateproducts(state, payload) {
            state.products = payload
        }

    },
    actions: {

        async commituser(context, response) {
            context.commit("authUser", {
                uid: response.headers.token
                // emailaddress: response.data.data.emailaddress
            })

            localStorage.setItem("uid", response.headers.token)
            // localStorage.setItem('emailaddress', response.data.data.emailaddress)
            // router.push('/dashboard')
            // dispatch('fetchUser')
        },

        async postEmailaddress(context, userData) {
            try {
                const response = await axios.post("/login/emailaddress", { emailaddress: userData.emailaddress })

                if (response.status == 200) {
                    context.state.emailaddressVerification = true
                }
            } catch (error) {
                // define specific error handling actions here
            }
        },

        async postTokenVerification(context, userData) {

            const postData = {
                emailaddress: userData.emailaddress,
                token: userData.verificationToken
            }

            try {
                const response = await axios.post("/login/tokenVerification", postData)

                if (response.status == 200) {
                    context.dispatch("commituser", response)
                    router.push("/dashboard")
                }
            } catch (error) {
                // define specific error handling actions here
            }
        },

        async postLogin(context, authData) {

            let passwordResponse

            try {
                passwordResponse = await axios.post("/login",
                    {
                        emailaddress: authData.emailaddress,
                        password: authData.password
                    }

                )

                if (passwordResponse.status == 200) {
                    context.dispatch("commituser", passwordResponse)
                    await context.dispatch("getPersonaldata")
                    router.push("/dashboard")
                }
            } catch (error) {
                // define specific error handling actions here
            }
        },

       async tryAutoLogin(context) {

            const uid = localStorage.getItem("uid")
            // const pid = localStorage.getItem("pid")
            // console.log(pid)


            if (uid) {
                // context.commit('authUser', {uid: uid})
                context.commit("authUser", {
                    uid: uid
                });
            //    await context.dispatch("getPersonaldata")
            //    await context.dispatch("setProduct", pid)
            }

            // if (pid) {
            //     // context.commit('authUser', {uid: uid})
            //     // context.commit("authUser", {
            //     //     uid: uid
            //     // });
            //    await context.dispatch("setProduct", pid)
            // }

        },

        logout(context) {
            context.commit("clearAuthData");
            localStorage.removeItem("uid");
            // localStorage.clear()
            router.push("/login");
            location.reload();
           
        },

        // async getProducts(context) {

        //     let products

        //     try {
        //         products = await axios.get("/products")

        //         if (products.status == 200 || products.status == 304) {

        //             context.commit("saveProducts", products.data.data.products)
        //         }
        //     }

        //     catch (error) {
        //         // define specific error handling actions here
        //     }
        // },

        stepsToRoutes(context, inputArray) {

            const stepsMapping = context.state.stepsMapping

            if (!inputArray[0]) {

                return ["/dashboard", "/registration/applyrequest"]

            }
            else {
                const routes = inputArray.map((step) => stepsMapping[step])
                const uniqueRoutes = new Set(routes)
                const backToArray = ["/dashboard", ...uniqueRoutes, "/registration/applyrequest"]
                return backToArray
            }
        }
    }
}
