<template> 
  <b-loading
    v-if="genericloader"
    v-model="isLoading"
    is-full-page
    :can-cancel="false"
  > 
    <!-- <b-icon
                    pack="fas"
                    icon="cog"
                    size="is-large"
                    custom-class="fa-spin">
                </b-icon> -->
  </b-loading>
</template>

<script>
import { mapGetters } from "vuex";


export default {
  name: "Loader",
  data() {
    return {
      isLoading: true,
      isFullPage: false,
    };
  },
  computed: {
     ...mapGetters([
      "genericloader",
    ])
  }

};
</script>