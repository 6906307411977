<template>
  <div class="container">
    <!-- <a>
      <b-icon
        @click.native="upwardRoute"
        pack="fas"
        icon="chevron-up"
        size="is-large"
        type="is-light"
      >
      </b-icon>
    </a> -->
    <div class="column is-8 is-offset-1">
      <h2 class="subtitle has-text-light">
        Productregistratie
      </h2>

      <h1
        v-if="!stepsDone.insuranceChoice"
        class="title has-text-light"
      >
        Mogen we je transactiegeschiedenis gebruiken om de meest geschikte
        producten te adviseren?
      </h1>
      <div
        v-if="!stepsDone.insuranceChoice"
        class="buttons"
      >
        <b-button
          :type="{
            'is-medium': true,
            'is-primary': true,
            'is-link': transactionflow,
          }"
          @click="
            (transactionflow = true),
            (userflow = false),
            requestplaidaccesstoken()
          "
        >
          Ja, dat mag zeker!
        </b-button>
        <b-button
          :type="{
            'is-medium': true,
            'is-primary': true,
            'is-link': userflow,
          }"
          @click="(userflow = true), (transactionflow = false)"
        >
          Nee, liever niet
        </b-button>
      </div>

      <div v-if="transactionResponse">
        <template v-if="transactionflow">
          <h2
            v-if="transactionflow"
            class="title has-text-light"
          >
            Op basis van je transacties adviseren we de volgende producten.
            Bepaal zelf voor welke producten je een eerlijk aanbod wil
            ontvangen!
          </h2>

          <!-- <b-switch rounded outlined size type="is-success" left-label>{{
            insurance.name
          }}</b-switch> -->
          <!-- {{ transactionResponse.advice }}
            {{ checkedNonAdvicedProducts }} -->
          <div class="column">
            <div
              v-for="insurance in transactionResponse.advice"
              :key="insurance.pid"
              class="block"
            >
              <b-tooltip
                :label="insurance.description"
                position="is-right"
              >
                <b-switch
                  :id="insurance.pid"
                  v-model="insurance.advice"
                  class="has-text-light"
                  type="is-link"
                  passive-type="is-light"
                >
                  <h2 class="subtitle has-text-light">
                    {{ insurance.name }}
                  </h2>
                </b-switch>
              </b-tooltip>
            </div>
          </div>

          <!-- <label :for="insurance.pid"
              ><h4>{{ insurance.name }}</h4></label
            > -->
        </template>
      </div>
      <template v-if="userflow || stepsDone.insuranceChoice">
        <h2
          v-if="userflow"
          class="title has-text-light"
        >
          Bepaal voor welke producten je een eerlijk aanbod wil ontvangen
        </h2>

        <h2
          v-if="stepsDone.insuranceChoice"
          class="title has-text-light"
        >
          Overzicht van jouw gekozen producten
        </h2>

        <!-- <b-switch rounded outlined size type="is-success" left-label>{{
            insurance.name
          }}</b-switch> -->

        <div
          v-for="product in productsStatus"
          :key="product.pid"
          class="block"
        >
          <b-tooltip
            :label="product.description"
            position="is-right"
          >
            <b-switch
              :id="product.pid"
              v-model="product.userStatus.inInsuranceChoice"
              class="has-text-light"
              type="is-link"
              passive-type="is-light"
            >
              <h2 class="subtitle has-text-light">
                {{ product.name }}
              </h2>
            </b-switch>
          </b-tooltip>
        </div>
        <b-button
          v-if="!userflow"
          :type="{
            'is-medium': true,
            'is-primary': true,
            'is-link': transactionflow,
          }"
          icon-left="sync-alt"
          @click="
            (transactionflow = true),
            (userflow = false),
            (stepsDone.insuranceChoice = ''),
            requestplaidaccesstoken()
          "
        >
          Lees mijn transacties in en geef me advies
        </b-button>
        <!-- <label :for="insurance.pid"
              ><h4>{{ insurance.name }}</h4></label
            > -->
      </template>
    </div>
    <a v-if="transactionResponse && transactionflow">
      <b-tooltip
        
        label="Dit zijn mijn keuzes, ga naar mijn dashboard"
        position="is-bottom"
        type="is-primary"
      >
        <b-icon
         
          pack="fas"
          icon="chevron-down"
          size="is-large"
          type="is-light"
          @click.native="
            setToDownward(),
            updateregistration({
              step: 'insuranceChoice',
              registrationdata: {
                insuranceChoice: checkedProducts,
              },
            })
          "
        />
      </b-tooltip>
      <!-- <p class="has-text-light">Ga naar de volgende stap</p>  -->
    </a>
    <a v-if="(productsStatus && userflow) || stepsDone.insuranceChoice">
      <b-tooltip
        
        type="is-primary"
        label="Dit zijn mijn keuzes, ga naar mijn dashboard"
        position="is-bottom"
      >
        <b-icon
         
          pack="fas"
          icon="chevron-down"
          size="is-large"
          type="is-light"
          @click.native="
            setToDownward(),
            updateregistration({
              step: 'insuranceChoice',
              registrationdata: {
                insuranceChoice: checkedNonAdviceProducts,
              },
            })
          "
        >
          checkedNonAdvicedProducts
        </b-icon>
      </b-tooltip>
      <!-- <p class="has-text-light">Ga naar de volgende stap</p>  -->
    </a>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Transactionregistration",
  inject: ["downwardRoute", "upwardRoute", "setToDownward"],
  data() {
    return {
      transactionflow: false,
      userflow: false,
      // givenpermission: false,
      noPSD2route: false,
      isActive: false,
      step: "insuranceChoice",
      // checkedProducts: []
    };
  },
  computed: {
    ...mapGetters(["transactionResponse", "productsStatus", "stepsDone"]),
    checkedProducts() {
      return this.transactionResponse.advice
        .filter((insurance) => insurance.advice)
        .map((pid) => pid.pid);
    },
    checkedNonAdviceProducts() {
      return this.productsStatus
        .filter((product) => product.userStatus.inInsuranceChoice)
        .map((pid) => pid.pid);
    },
  },
  async created()  {
    
      await this.getRegistrationstatusData();
     await  this.getProductsStatus();
      // if (!this.product) {
      //    this.setProduct(localStorage.getItem("pid"));
      // }
    
  },
  mounted: function () {
    // this.getProductsStatus();
    // this.getregistration()
  },
  methods: {
    ...mapActions([
      "requestplaidaccesstoken",
      "updateregistration",
      "getProductsStatus",
      "getregistration",
      "getRegistrationstatusData",
      "setProduct",
      "getProductsStatus",
    ]),
    // updateTransitionName() {
    //   this.$emit("updateTransition");
    // },
  },
};
</script>
