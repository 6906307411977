<template>
  <div class="container">
    <div class="column is-half">
      <h2 class="subtitle has-text-light">
        Autoregistratie
      </h2>

      <h1 class="title has-text-light">
        Wat is je kenteken?
      </h1>
      <ValidationObserver class="box">
        <form
          @submit.prevent="
            updateregistration({
              step: 'licenseplate',
              registrationdata: {
                licenseplate: cardata.kenteken
                  .toUpperCase()
                  .replace(/[-/_]/g, ''),
              },
            })
          "
        >
          <BInputWithValidation
            v-model="cardata.kenteken"
            vid="any"
            name="Kenteken"
            rules="required"
            type="input"
            label="Kenteken"
            icon="car"
            buttontext="Verzenden"
            buttonicon="paper-plane"
            expanded-input
          />
          <!-- <b-button
            native-type="submit"
            class="is-link"
          >
            Verzend
          </b-button> -->
        </form>
      </ValidationObserver>
      <template v-if="stepsDone.licenseplate">
        <h1 class="title has-text-light">
          Jouw autogegevens
        </h1>
        <div class="box">
          <div
            v-for="(value, name) in relevantcardata"
            :key="name"
            class="has-text-light"
          >
            <div
              v-if="name != 'Kenteken'"
              class="columns"
            >
              <div
                class="column has-text-left"
              >
                {{ name }}:
              </div>
              <div class="column has-text-right">
                {{ value }}
              </div>
            </div>
          </div>
        </div>
        <a @click="toNextRoute($route.path)">
          <b-icon
            pack="fas"
            icon="chevron-down"
            size="is-large"
            type="is-light"
          />
          <span class="has-text-light">Dit is mijn auto</span>
          <!-- <p class="has-text-light">Ga naar de volgende stap</p>  -->
        </a>
        <!-- <b-checkbox
          v-model="checkcar"
          type="is-link"
          class="has-text-light"
          @change.native="
            toNextRoute(
              $route.path,
            )
          "
        >
          Dit is mijn auto
        </b-checkbox> -->
      </template>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import BInputWithValidation from "../../components/inputs/BInputWithValidation";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Licenceplateregistration",
  components: {
    ValidationObserver,
    BInputWithValidation,
  },
  data() {
    return {
      licenseplate: "",
      checkcar: false,
    };
  },
  computed: {
    ...mapGetters(["cardata", "stepsDone", "relevantcardata"]),
  },
  methods: {
    ...mapActions(["updateregistration", "toNextRoute"]),

  },
};
</script>

<style lang="scss">
</style>