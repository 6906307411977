<template>
  <div class="hero">
    <div
      class="hero-body"
    >
      <div class="columns">
        <div class="column is-1 has-text-centered">
          <a v-if="previousRoute">
            <b-icon
              pack="fas"
              icon="chevron-up"
              size="is-large"
              type="is-light"
              @click.native="upwardRoute"
            />
            <!-- <p class="has-text-light">Ga naar de volgende stap</p>  -->
          </a>
        </div>
   
        <div class="column">
          <transition
            :name="transitionName"
            mode="out-in"
          >
            <!-- <keep-alive> -->
            <router-view />
            <!-- </keep-alive> -->
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "../router";

export default {
  name: "Registration",
  components: {},
  provide() {
    return {
      downwardRoute: this.downwardRoute,
      upwardRoute: this.upwardRoute,
      setToDownward: this.setToDownward,
      nextRoute: this.nextRoute,
    };
  },
  data() {
    return {
      // transitionName: "slideDown",
    };
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "uid",
      "products",
      "product",
      "preRegistrationRoutes",
      "transitionName",
      // "transitionName"
    ]),
    // currentPath() {
    //   return this.$route.path 
    // },
    steps() {
      // console.log(this.preRegistrationRoutes)
      return this.preRegistrationRoutes;
    },
    stepsIndex() {
      return this.steps.indexOf(this.$route.path);
    },
    previousRoute() {
      // console.log(this.steps[this.stepsIndex - 1])
      return this.steps[this.stepsIndex - 1]
    }
    // percent() {
      
    //   return (this.stepsIndex / this.steps.length) * 95 + 5
    // },
  },
  async created() {

  },
  mounted: async function () {
  
  },
  methods: {
    ...mapActions([
        "setTransitionName",
    ]),
    //     shownextroute() {
    //       const steps = router.options.routes.find(x => x.name === 'registration').children.map(function(e) { return e.path; })
    //       const stepsIndex = steps.indexOf(this.$route.path.split("/").pop())
    //       const nextRoute = steps[stepsIndex + 1]
    //       router.push({path: nextRoute})
    // },
    downwardRoute() {
      // console.log(this.$route.path)
       this.setTransitionName["slideDown"];
      // console.log(this.nextRoute);
      const nextRoute = this.steps[this.stepsIndex + 1]
      // console.log(nextRoute) 
      router.push({ path: nextRoute });
    },
    upwardRoute() {
      this.setTransitionName["slideUp"];
      // console.log(this.previousRoute);
      // const previousRoute = this.steps[this.stepsIndex - 1]
      // console.log(previousRoute)
      router.push({ path: this.previousRoute });
    },
    setToDownward() {
      this.transitionName = "slideDown";
    },
  },
};
</script>

<style lang="scss" scoped>
///////////////////////////// slide up animation /////////////////////////////

.slideUp-enter-active {
  animation: slide-in-up 300ms ease-out forwards;
}

.slideUp-leave-active {
  animation: slide-out-up 300ms ease-out backwards;
}

@keyframes slide-in-up {
  from {
    transform: translateY(-40px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out-up {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(40px);
    opacity: 0;
  }
}

///////////////////////////// slide down animation /////////////////////////////

.slideDown-enter-active {
  animation: slide-in-down 300ms ease-out forwards;
}

.slideDown-leave-active {
  animation: slide-out-down 300ms ease-out backwards;
}

@keyframes slide-in-down {
  from {
    transform: translateY(40px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out-down {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-40px);
    opacity: 0;
  }
}
</style>