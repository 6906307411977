<template>
  <div class="">
    <b-navbar
      type="is-primary"
      transparent
    >
      <template #brand>
        <b-navbar-item
          tag="router-link"
          to="/"
        >
          Involute | verzekeren
        </b-navbar-item>
      </template>
      <template #start>
        <!-- <b-navbar-dropdown label="Mijn Involute">
          <b-navbar-item
            v-if="isAuthenticated"
            tag="router-link"
            to="dashboard"
          >
            Producten
          </b-navbar-item>
          <b-navbar-item
            v-if="isAuthenticated"
            tag="router-link"
            to="dashboard"
          >
            Account
          </b-navbar-item>
        </b-navbar-dropdown> -->
        <!-- <b-navbar-item
          v-if="isAuthenticated"
          tag="router-link"
          to="dashboard"
        >
          mijn producten
        </b-navbar-item> -->
      </template>
      <!-- <template #start>
        <b-navbar-item

          v-if="isAuthenticated"
          tag="router-link"
          to="dashboard"
        >
          <b-button class="button is-link">
            Dashboard
          </b-button>
        </b-navbar-item>

      </template> -->

      <!-- <template #burger isOpened="false"> <b-navbar-item href="#">
                    Over Involute
                </b-navbar-item>
                <b-navbar-item href="#">
                    Contact
                </b-navbar-item></template> -->

      <template #end>
        <b-navbar-item
          v-if="isAuthenticated"
          tag="router-link"
          to="/dashboard"
        >
          Mijn dashboard
        </b-navbar-item>
        <b-navbar-item
          v-if="isAuthenticated"
          tag="router-link"
          to="/account"
        >
          Mijn account
        </b-navbar-item>
        <!-- <b-navbar-dropdown
          v-if="isAuthenticated"
          label="Mijn Involute"
        >
          <b-navbar-item
            
            tag="router-link"
            to="dashboard"
          >
            Dashboard
          </b-navbar-item>
          <b-navbar-item
           
            tag="router-link"
            to="account"
          >
            Account
          </b-navbar-item>
        </b-navbar-dropdown> -->
        <b-navbar-item
          v-if="!isAuthenticated"
          tag="router-link"
          to="/login"
        >
          <b-button
            icon-left="sign-in-alt"
            class="button is-link"
          >
            Log in
          </b-button>
        </b-navbar-item>

        <!-- <b-navbar-item
          v-if="isAuthenticated && personalData"
          tag="router-link"
          to="/login"
        >
          Ingelogd als: {{ personalData.emailaddress }}
        </b-navbar-item> -->
      
        <b-navbar-item v-if="isAuthenticated">
          <b-button
            icon-left="sign-out-alt"
            class="button is-link"
            @click="logout"
          > 
            Logout
          </b-button>
        </b-navbar-item>
      </template>
    </b-navbar>
  

    <!-- {{showNavbarBorder}} -->
    <b-progress
      v-if="showNavbarBorder"
      type="is-link"
      size="is-small"
      :value="100"
    />
    <!-- {{showNavbar}} -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import router from "../router"

export default {
  name: "Navbar",
  computed: {
    ...mapGetters(["isAuthenticated", "personalData"]),
    showNavbarBorder() {
      if (this.$route.matched[0].name == "registration") return false;
      else return true;
    },
  },
  created: function () {
    //   if(this.isAuthenticated) {
    //   this.getPersonaldata()
    //   }
  },
  methods: {
    ...mapActions(["getPersonaldata", "logout"]),
    // showNavbar() {
    //     console.log(this.$route.matched[0].name)
    // }
    //   onLogout() {
  },
};
</script>

<style lang="scss">
// .navbar {
//      background: transparent;
// }
</style>