import axios from "axios"

const url = "/realm/user/image/"


export default {
    state: {
        imageIDexample: "blobexample",
        imageloading: false
    },
    mutations: {
        saveImage(state, payload) {
            state[payload.imageID] = payload.blob;
        }
    },
    getters: {
        imageloading(state) {
            return state.imageloading
        }
    },
    actions: {
        async getImage(context, imageID) {
            // console.log(imageID)
            if (!imageID) { return } else {
                context.state.imageloading = true
              try {
                let imgData =  await axios.get(url + imageID,  {
                    responseType: 'blob',
                    headers: {noLoader: true}
                    
                })
                if(imgData.status == "200") {
                let blob = window.URL.createObjectURL(imgData.data)
                context.commit("saveImage", { blob, imageID })
                context.state.imageloading = false

                return blob
                }
              } catch (error) {
                  console.log(error)
                  //error handling
                  
              } 
            }
        }
    }
}