<template>
  <ValidationProvider
    v-slot="{ errors, invalid }"
    :mode="mode"
    :vid="vid"
    :name="$attrs.name || $attrs.label"
    :rules="rules"
    slim
  >
    <b-field
      v-bind="$attrs"
      :type="{ 'is-danger': errors[0], 'is-success': success }"
      :message="errors"
      expanded
    >
      <b-numberinput
        v-if="numberinput"
        v-bind="$attrs"
        ref="provider"
        v-model="innerValue"
        type="is-link"
        expanded
      />
      <b-input
        v-else
        v-bind="$attrs"
        ref="provider"
        v-model="innerValue"
        :expanded="expandedInput"
        :icon="icon"
        @blur.prevent="if(blurMethod && !invalid) {blurMethod()}"
      />
      <p
        v-if="buttontext"
        class="control"
      >
        <b-button
          class="is-link"
          native-type="submit"
          :disabled="invalid"
          :label="buttontext"
          :icon-left="buttonicon"
        />
      </p>
    </b-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    mode: {
      type: String,
      default: "aggressive"
    },
    vid: {
      type: String,
      default: "",
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    expandedInput: {
      type: Boolean,
    },
    buttontext: {
      type: String,
      default: "",
    },
    buttonicon: {
      type: String,
      default: "",
    },
    nopositiveFB: {
      type: Boolean,
    },
    success: {
      type: Boolean,
    },
    showvalidation: {
      type: Boolean,
    },
    fieldset: {
      type: Boolean,
    },
    icon: {
      type: String,
      default: "",
    },
    numberinput: {
      type: Boolean,
    },
    // must be included in props
    value: {
      type: null,
      default: "",
    },
    blurMethod: {
      type: Function,
      default: () => {  }
    }
  },
  data: () => ({
    innerValue: "",
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
    watch: {
      // Handles internal model changes.
      // errormessage() {
      //   this.$refs.provider.setErrors({
      //     email: [this.errormessage],
      //   });
      // },
      // Handles external model changes.
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  // methods: {
  //   runBlurMethod() {
  //     this.blurMethod()
  //   }
  // }
};
</script>
