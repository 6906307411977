<template>
  <div class="container">
    <!-- {{ passportApidata }} -->
    <!-- <a>
      <b-icon
        pack="fas"
        icon="chevron-up"
        size="is-large"
        type="is-light"
        @click.native="upwardRoute"
      />
    </a> -->
    <div class="column is-9">
      <h2 class="subtitle has-text-light">
        Persoonsregistratie
      </h2>
      
      <template v-if="!stepsDone.passportUser || !stepsDone.passportApi">
        <h1 class="title has-text-light">
          Ben je in het bezit van een duidelijke paspoortfoto?
        </h1>

        <div class="buttons">
          <b-upload
            v-model="uploadImage"
            @input="uploadImageandupdateregistration()"
          >
            <a
              :class="{
                button: true,
                'is-primary': true,
                'is-link': uploadImageAllowed,
                'is-medium': true,
              }"
              @click="
                (uploadImageAllowed = true),
                (camAllowedPassport = false),
                stopCam()
              "
            >
              <b-icon icon="upload" />
              <span>{{ uploadImage.name || "Ja, upload hier" }}</span>
            </a>
          </b-upload>
          <b-button
            :type="{
              'is-primary': true,
              'is-medium': true,
              'is-link': camAllowedPassport,
            }"
            @click="allowCam('passport'), (uploadImageAllowed = false)"
          >
            Nee, ik maak een foto met mijn webcam
          </b-button>
        </div>
      </template>
      <template v-if="skipPassportStep">
        <h1 class="title has-text-light">
          Ga snel door naar de volgende stap!
        </h1>
      </template>
      <template v-if="stepsDone.passportApi && !skipPassportStep">
        <h1 class="title has-text-light">
          Jouw persoonsgegevens
        </h1>
        <!-- <b-button
          v-if="stepsDone.passportApi && stepsDone.passportUser"
          expanded
          icon-left="sync-alt"
          class="is-primary"
          @click="clearPassportdata"
        >
          Begin opnieuw
        </b-button> -->
        <!-- <br> -->
        <!-- {{ passportImage }} -->
        <div class="box">
          <article class="media">
            <div class="media-left">
              <div
               
                class="box"
              >
                <figure
                
                  class="image is-128x128 passportimage"
                >
                  <Imageloader v-if="imageloading" />
                  <img
                    v-else-if="stepsDone.passportApi && passportApidata.image"
                    class=""
                    :src="passportImage"
                  >
                </figure> 
              </div>
            </div>
            <div class="media-content">
              <div class="content">
                <ValidationObserver
                  ref="observer"
                  class=""
                >
                  <form>
                    <b-field grouped>
                      <BInputWithValidation
                        v-model.trim="passportUserdata.name"
                        vid="name"
                        name="name"
                        rules="required"
                        type="input"
                        label="Voornamen"
                        icon="user"
                      />
                      <BInputWithValidation
                        v-model.trim="passportUserdata.surname"
                        vid="name"
                        name="name"
                        rules="required"
                        type="input"
                        label="Achternaam"
                        icon="user"
                      />
                    </b-field>
                    <b-field>
                      <BCheckboxesWithValidation
                        vid="sex"
                        name="sex"
                        rules="required"
                        label="Geslacht"
                      >
                        <b-radio
                          v-model="passportUserdata.sex"
                          type="is-link"
                          name="name"
                          native-value="F"
                        >
                          Vrouw
                        </b-radio>
                        <b-radio
                          v-model="passportUserdata.sex"
                          type="is-link"
                          name="name"
                          native-value="M"
                        >
                          Man
                        </b-radio>
                        <b-radio
                          v-model="passportUserdata.sex"
                          type="is-link"
                          name="name"
                          native-value="X"
                        >
                          X
                        </b-radio>
                      </BCheckboxesWithValidation>
                    </b-field>
                    <BSelectWithValidation
                      v-model="passportUserdata.nationality"
                      rules="required"
                      label="Nationaliteit"
                      icon="globe"
                    >
                      <option
                        v-for="country in countries"
                        :key="country.key"
                        :value="country.key"
                      >
                        {{ country.text }}
                      </option>
                    </BSelectWithValidation>
                    <b-field label="Geboortedatum">
                      <b-datepicker
                        v-model="passportUserdata.birth_date"
                        
                        pack="fas"
                        icon="calendar"
                        trap-focus
                      />
                    </b-field>
                    <b-button
                      v-if="stepsDone.passportApi"
                      size="is-small"
                      icon-left="sync-alt"
                      class="is-primary"
                      @click="clearPassportdata"
                    >
                      Begin opnieuw
                    </b-button>
                   
                    
                    <!-- <b-button
              
              icon-left="check"
              type="is-primary"
              expanded
              @click="updateregistration({
                step: 'passportUser',
                registrationdata: passportdatamodel,
              })"
            >
              Deze gegevens kloppen
            </b-button> -->

                    <!-- <br>
                    <br> -->
                    

                    <!-- <b-button v-else native-type="submit" type="is-primary" expanded
              >Wijzigingen doorvoeren</b-button
            > -->
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </article>
        </div>
        <!-- <b-button
          
          type="is-primary"
          icon-left="chevron-down"
          @click="
            updateregistration({
              step: 'passportUser',
              registrationdata: passportUserdata,
              route: $route.path,
            })
          "
        >
          Dit klopt!
        </b-button> -->

        <a 
          @click="
            updateregistration({
              step: 'passportUser',
              registrationdata: passportUserdata,
              route: $route.path,
            })
          "
        >
          <b-icon
            pack="fas"
            icon="chevron-down"
            size="is-large"
            type="is-light"
          />
          <span class="has-text-light">Dit zijn de juiste gegevens</span>
        </a>
        <!-- 
        <b-button
          v-if="stepsDone.passportApi && stepsDone.passportUser"
          expanded
          icon-left="sync-alt"
          class="is-primary"
          @click="clearPassportdata"
        >
          Begin opnieuw
        </b-button> -->
      </template>
      <div
        v-show="camAllowedPassport && !uploadImageAllowed"
        class="column is-half"
      >
        <video
          v-show="
            camAllowedPassport && !uploadImageAllowed && !stepsDone.passportUser
          "
          id="video"
          ref="video"
        />
        <canvas
          id="canvas"
          ref="canvas"
        />
        <b-button
          v-if="
            camAllowedPassport && !uploadImageAllowed && !stepsDone.passportUser
          "
          expanded
          icon-left="camera"
          type="is-primary"
          @click="capturepassport()"
        >
          Maak foto
        </b-button>
        
        <!--           
          <label
            v-if="camAllowed && !stepsDone.passportApi"
            v-on:click="capturepassport()"
            class="ui black fluid button container"
          >
            <i class="ui camera icon"></i>
            Maak foto
          </label> -->
      </div>
    </div>
   
    <!-- <a>
      <b-icon
        v-if="checkinfo"
        pack="fas"
        icon="chevron-down"
        size="is-large"
        type="is-light"
        @click.native="
          downwardRoute,
          updateregistration({
            step: 'passportUser',
            registrationdata: passportdatamodel,
          })
        "
      />
    </a> -->
  </div>
</template>

<script>
// import axios from 'axios'
// import {
//   required,
//   // minValue
// } from "vuelidate/lib/validators";
import { mapActions, mapGetters, mapMutations } from "vuex";
// import Loader from "../loader";
import { ValidationObserver } from "vee-validate";
import BInputWithValidation from "../../components/inputs/BInputWithValidation";
import BCheckboxesWithValidation from "../../components/inputs/BCheckboxesWithValidation";
import BSelectWithValidation from "../../components/inputs/BSelectWithValidation";
import Imageloader from "../shared/imageloader"

// import countries from '../../assets/countries.js'
// import $ from "jquery";

export default {
  name: "Clientregistration",
  components: {
    ValidationObserver,
    BInputWithValidation,
    BCheckboxesWithValidation,
    BSelectWithValidation,
    Imageloader,
    // Loader,
  },
  inject: ["downwardRoute", "upwardRoute"],
  beforeRouteLeave(to, from, next) {
    this.stopCam();
    next();
  },

  data() {
    return {
      checkinfo: false,
      // selected: new Date(),
      camAllowedPassport: false,
      camAllowedFace: false,
      video: {},
      canvas: {},
      uploadImage: [],
      passportAllowed: false,
      skipPassportStep: false,
      skipFaceStep: false,
      passportImage: this.$store.state.image[this.imageID],
      uploadImageAllowed: false,
      // countries: countries, //from external countries file
      countries: [{ key: "NLD", value: "NLD", flag: "nl", text: "Nederlands" }],
    };
  },
  computed: {
    ...mapGetters([
      "passportUserdata",
      "passportApidata",
      "stepsDone",
      "freeze",
      "imageloading"
    ]),
    // passportImage() {
    //   return this.$store.state.image[this.imageID];
    // },
    imageID() {
      return this.passportApidata.image;
    },
  },

  watch: {
    // whenever question changes, this function will run
    freeze: function (freeze) {
      if (!freeze && this.camAllowedPassport) {
        this.allowCam();
      }
    },

  },
  // updated: async function () {
  //   this.fetchImage();
  // },
  mounted: function () {
    this.fetchImage();
    if(this.stepsDone.passportApi && !this.stepsDone.passportUser)
    {this.clearPassportdata()}
  },
  methods: {
    async fetchImage() {
      if (!this.imageID) {
        return false;
      } else if (!this.$store.state.image[this.imageID]) {
        this.passportImage = await this.getImage(this.imageID);
      } else {
        this.passportImage = this.$store.state.image[this.imageID];
      }
    },
    ...mapMutations(["clearPassportdata"]),
    ...mapActions(["updateregistration", "getImage"]),

    allowCam() {
      // console.log("camera is geoorloofd");
      // console.log('hoi')
      this.video = this.$refs.video;
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ audio: false, video: true })
          .then((stream) => {
            this.stream = stream;
            this.video.srcObject = stream;
            this.video.play();
            this.camAllowedPassport = true;
          });
      }
    },
    sharpen(ctx, w, h, mix) {
      var x;
      var sx;
      var sy;
      var r;
      var g;
      // var a
      var b;
      var dstOff;
      var srcOff;
      var wt;
      var cx;
      var cy;
      var scy;
      var scx;
      var weights = [0, -1, 0, -1, 5, -1, 0, -1, 0];
      var katet = Math.round(Math.sqrt(weights.length));
      var half = (katet * 0.5) | 0;
      var dstData = ctx.createImageData(w, h);
      var dstBuff = dstData.data;
      var srcBuff = ctx.getImageData(0, 0, w, h).data;
      var y = h;

      while (y--) {
        x = w;
        while (x--) {
          sy = y;
          sx = x;
          dstOff = (y * w + x) * 4;
          r = 0;
          g = 0;
          // a = 0
          b = 0;

          for (cy = 0; cy < katet; cy++) {
            for (cx = 0; cx < katet; cx++) {
              scy = sy + cy - half;
              scx = sx + cx - half;

              if (scy >= 0 && scy < h && scx >= 0 && scx < w) {
                srcOff = (scy * w + scx) * 4;
                wt = weights[cy * katet + cx];

                r += srcBuff[srcOff] * wt;
                g += srcBuff[srcOff + 1] * wt;
                b += srcBuff[srcOff + 2] * wt;
                // a += srcBuff[srcOff + 3] * wt
              }
            }
          }
          var tot = parseInt((r + g + b) / 3);
          dstBuff[dstOff] = tot * mix + srcBuff[dstOff] * (1 - mix);
          dstBuff[dstOff + 1] = tot * mix + srcBuff[dstOff + 1] * (1 - mix);
          dstBuff[dstOff + 2] = tot * mix + srcBuff[dstOff + 2] * (1 - mix);
          dstBuff[dstOff + 3] = srcBuff[dstOff + 3];
        }
      }

      ctx.putImageData(dstData, 0, 0);
    },
    capturepassport() {
      var canvas = this.$refs.canvas;
      // var video = this.$refs.video
      console.log(this.video);
      var width = this.video.videoWidth;
      var height = this.video.videoHeight;

      canvas.width = width;
      canvas.height = height;

      var left = width * 0.05;
      var top = height * 0.05;
      var widthcanvas = width * 0.9;
      var heightcanvas = height * 0.9;

      var context = canvas.getContext("2d");
      context.drawImage(
        this.video,

        left,
        top, // Start at 70/20 pixels from the left and the top of the image (crop),
        widthcanvas,
        heightcanvas, // "Get" a `50 * 50` (w * h) area from the source image (crop),
        0,
        0, // Place the result at 0, 0 in the canvas,
        width,
        height
      ); // With as width / height: 100 * 100 (scale)

      this.sharpen(context, width, height, 1);
      this.passportImage = canvas.toDataURL("image/png");
      this.stopCam();

      this.updateregistration({
        step: "passportApi",
        registrationdata: { passport: this.passportImage },
      });
    },
    stopCam() {
      if (this.stream != null) {
        const tracks = this.stream.getTracks();
        tracks.map((track) => track.stop());
      }
      // console.log(this.mediaStream)
      // const tracks = this.mediaStream.getTracks()
      // console.log(tracks)

      // this.video = this.$refs.video;
      // console.log(navigator.mediaDevices)
    },
    uploadImageandupdateregistration() {
      // console.log(this.imagetest)
      // const image = this.imagetest;
      // console.log(image);
      const reader = new FileReader();
      reader.readAsDataURL(this.uploadImage);
      reader.onload = (e) => {
        this.passportImage = e.target.result;
        // console.log(this.passportApidata.image);
        // this.stopCam();
        this.updateregistration({
          step: "passportApi",
          registrationdata: { passport: this.passportImage },
        });
      };
    },
    ...mapActions(["getregistration"]),
  },
};
</script>

<style scoped>
.passportimage {
  height: 50%;
}

#video-container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 5%;
  left: 5%;
  bottom: 5%;
  right: 5%;
  margin: 0;
  /* padding: 0.5em 0.5em 0.5em 2em; */
  border: 0.2em solid rgba(242, 47, 70, 0.8);
  /* background-image: url('../../assets/paspoort2.png');
  background-repeat: no-repeat; */
  /* opacity: 20%; */
  /* background: rgba(242, 47, 70, 0.8); */
  color: #fff;
  font-size: 16px;
  line-height: 1;
}

#video {
  background-color: #000000;
  border-width: 2px;
  border-style: solid;
  border-color: white;
  /* width: 50%;
  height: 100%; */
  z-index: 1;
}

#canvas {
  display: none;
}

.field.invalid input {
  border: 1px solid red;
  /* background-color: #ffc9aa; */
}

.grid.container {
  margin-top: 5em;
}

</style>
