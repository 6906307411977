<template>
  <div class="container">
    <div class="column is-half">
      <h2 class="subtitle has-text-light">
        Objectregistratie
      </h2>

      <h1 class="title has-text-light">
        Wat is je postcode?
      </h1>
      <ValidationObserver
        ref="observer"
        v-slot="{ invalid }"
        class="box"
      >
        <form
          @submit.prevent="
            updateregistration({
              step: 'houseinfoApi',
              registrationdata: {
                number: houseinfodata.number,
                zipcode: houseinfodata.zipcode,
                addressId: houseinfodata.addressId,
              },
            })
          "
        >
          <b-field grouped>
            <BInputWithValidation
              v-model.trim="houseinfodata.zipcode"
              vid="any"
              name="Postcode"
              rules="required|length:6"
              type="input"
              label="Postcode"
              icon="envelope"
              :success="stepsDone.houseinfoApi"
            />

            <!-- <input
              @change.prevent="
                updateregistration({
                  step: 'houseinfoUser',
                  registrationdata: houseinfodata,
                })
              "
            > -->

            <BInputWithValidation
              v-model.trim="houseinfodata.number"
              vid="any"
              name="Huisnummer"
              rules="required|integer"
              type="input"
              label="Huisnr"
              icon="list-ol"
              :success="stepsDone.houseinfoApi"
            />
            <BSelectWithValidation
              v-if="numberadditionOpt[0] && stepsDone.houseinfoApi"
              v-model.trim="houseinfodata.numberaddition"
              vid="any"
              name="Huisnummertoevoeging"
              type="input"
              label="Toevoeging"
              icon="plus"
              :success="stepsDone.houseinfoApi"
            >
              <option>
                {{ houseinfodata.numberaddition }}
              </option>
            </BSelectWithValidation>
            <BSelectWithValidation
              v-if="numberadditionOpt[0] && !stepsDone.houseinfoApi"
              v-model.trim="houseinfodata.addressId"
              vid="any"
              name="Huisnummertoevoeging"
              rules="required"
              label="Toevoeging"
              :success="stepsDone.houseinfoApi"
              icon="plus"
            >
              <option
                v-for="option in numberadditionOpt"
                :key="option.addressId"
                :value="option.addressId"
              >
                {{ option.display }}
              </option>
            </BSelectWithValidation>
          </b-field>
          <div class="buttons">
            <b-button
              v-if="!stepsDone.houseinfoApi"
              icon-left="paper-plane"
              native-type="submit"
              class="is-link"
              :disabled="invalid"
              @click="checkinfo = false"
            >
              Verzend
            </b-button>
            <b-button
              icon-left="sync-alt"
              class="is-primary"
              @click="clearPropertydata(), (houseImage = null)"
            >
              Begin opnieuw
            </b-button>
          </div>
        </form>
      </ValidationObserver>
      <div v-if="stepsDone.houseinfoApi">
        <h1 class="title has-text-light">
          Controleer je gegevens of vul aan
        </h1>

        <ValidationObserver
          ref="observer"
          class="box"
        >
          <Imageloader v-if="imageloading" />
          <b-image
            v-else-if="stepsDone.houseinfoApi && houseinfodata.houseinfo.image"
            :src="houseImage"
            ratio="2by1"
          />
          <br>
          <form
            @submit.prevent="
              updateregistration({
                step: 'houseinfoUser',
                registrationdata: houseinfodata,
              })
            "
          >
            <b-field grouped>
              <BInputWithValidation
                v-model.trim="houseinfodata.street"
                vid="any"
                name="Straat"
                rules="required"
                type="input"
                label="Straat"
                icon="road"
              />

              <BInputWithValidation
                v-model.trim="houseinfodata.city"
                vid="any"
                name="Plaats"
                rules="required"
                type="input"
                label="Plaats"
                icon="building"
              />
            </b-field>

            <b-field grouped>
              <BInputWithValidation
                v-model="houseinfodata.houseinfo.yearOfConstruction"
                vid="any"
                name="Bouwjaar"
                rules="required|integer"
                label="Bouwjaar"
                numberinput
              />
              <BInputWithValidation
                v-model="houseinfodata.houseinfo.area"
                vid="any"
                name="Oppervlakte"
                rules="required|integer"
                label="Oppervlakte (m2)"
                icon="square"
                numberinput
              />
              <BInputWithValidation
                v-model="houseinfodata.houseinfo.status"
                vid="any"
                name="Status"
                type="input"
                rules="required"
                label="Status"
                icon="lock"
              />
            </b-field>
            <!-- <b-image
              v-if="stepsDone.houseinfoApi && houseinfodata.houseinfo.image"
              :src="houseImage"
              ratio="2by1"
            /> -->
            <!-- <br>
            <b-button
              expanded
              native-type="submit"
              type="is-primary"
            >
              Bovenstaande gegevens zijn juist
            </b-button> -->
          </form>
          <!-- <br> -->
          <!-- <b-field>
            <b-checkbox
              v-model="checkinfo"
              type="is-link"
              class="has-text-light"
              @change.native="
                updateregistration({
                  step: 'houseinfoUser',
                  registrationdata: houseinfodata,
                  route: $route.path,
                })
              "
            >
              Deze gegevens kloppen
            </b-checkbox>
          </b-field> -->
        </ValidationObserver>
        <a
          @click="
            updateregistration({
              step: 'houseinfoUser',
              registrationdata: houseinfodata,
              route: $route.path,
            })
          "
        >
          <b-icon
            pack="fas"
            icon="chevron-down"
            size="is-large"
            type="is-light"
          />
          <span class="has-text-light">Dit zijn de juiste gegevens</span>
          <!-- <p class="has-text-light">Ga naar de volgende stap</p>  -->
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";

import { ValidationObserver } from "vee-validate";
import BInputWithValidation from "../../components/inputs/BInputWithValidation";
import BSelectWithValidation from "../../components/inputs/BSelectWithValidation";
import Imageloader from "../shared/imageloader";

export default {
  name: "Propertyregistration",
  components: {
    ValidationObserver,
    BInputWithValidation,
    BSelectWithValidation,
    Imageloader,
  },
  data() {
    return {
      step: "Propertyregistration",
      checkinfo: false,
      houseImage: null,
    };
  },

  computed: {
    ...mapGetters([
      "numberadditionOpt",
      "houseinfodata",
      "stepsDone",
      "imageloading",
    ]),
    imageID() {
      return this.houseinfodata.houseinfo.image;
    },
  },
  watch: {
    // whenever question changes, this function will run
    imageID: function () {
      this.fetchImage();
    },
  },
  mounted: async function () {
    this.fetchImage();
  },
  methods: {
    async fetchImage() {
      if (!this.imageID) {
        return false;
      } else if (!this.$store.state.image[this.imageID]) {
        this.houseImage = await this.getImage(this.imageID);
      } else {
        this.houseImage = this.$store.state.image[this.imageID];
      }
    },
    ...mapMutations(["clearPropertydata"]),
    ...mapActions(["updateregistration", "getImage"]),
  },
};
</script>

<style lang="scss" scoped>
.field.invalid input {
  border: 1px solid red;
  //  background-color: #ffc9aa;
}
</style>
