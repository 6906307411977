<template>
  <ValidationProvider
    v-slot="{ errors }"
    :vid="vid"
    :name="$attrs.label"
    :rules="rules"
  >
    <div class="block">
      <b-field
        :label="$attrs.label"
        type="is-light"
        class="has-text-light"
      >
        <slot />
        <p class="has-text-danger">
          {{ errors[0] }}
        </p>
      </b-field>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    vid: {
      type: String,
      default: "",
    },
    rules: {
      type: [Object, String],
      default: "",
    },
  },
};
</script>
