<template>
  <b-modal
    v-model="isModalActive"
    scroll="keep"
    :can-cancel="false"
  >
    <div class="card">
      <div class="card-content">
        <div class="content has-text-centered">
          <div class="columns has-text-centered is-multiline">
            <div class="column has-text-centered is-12">
              <h1 class="title has-text-primary">
                De veiling wordt gestart... 
                <!-- <b-icon
              class="fas fa-gavel fa-pulse"
              size="large"
              type="is-link"
            /> -->
              </h1>
            </div>
            <div class="column has-text-centered is-offset-quarter">
              <div class="column is-offset-one-quarter">
                <barloader />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Barloader from './barloader.vue';

export default {
  name: "Auctionloader",
  components: {
    Barloader
  },
  data() 
    {
    return {
      isModalActive: true,
      isFullPage: true,
      
    };
  },
  mounted: function() {
    // this.openLoading()
  },
    methods: {
      // openLoading() {
      //   this.isModalActive = true;
      //   setTimeout(() => {
      //     this.isModalActive = false;
      //   }, 5000);
      // },
  },
};
</script>


<style lang="scss" scoped>

.card {
  background-color: white;
}

</style>