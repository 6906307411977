var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"column is-8 is-offset-1"},[_c('h2',{staticClass:"subtitle has-text-light"},[_vm._v(" Productregistratie ")]),(!_vm.stepsDone.insuranceChoice)?_c('h1',{staticClass:"title has-text-light"},[_vm._v(" Mogen we je transactiegeschiedenis gebruiken om de meest geschikte producten te adviseren? ")]):_vm._e(),(!_vm.stepsDone.insuranceChoice)?_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"type":{
          'is-medium': true,
          'is-primary': true,
          'is-link': _vm.transactionflow,
        }},on:{"click":function($event){(_vm.transactionflow = true),
          (_vm.userflow = false),
          _vm.requestplaidaccesstoken()}}},[_vm._v(" Ja, dat mag zeker! ")]),_c('b-button',{attrs:{"type":{
          'is-medium': true,
          'is-primary': true,
          'is-link': _vm.userflow,
        }},on:{"click":function($event){(_vm.userflow = true), (_vm.transactionflow = false)}}},[_vm._v(" Nee, liever niet ")])],1):_vm._e(),(_vm.transactionResponse)?_c('div',[(_vm.transactionflow)?[(_vm.transactionflow)?_c('h2',{staticClass:"title has-text-light"},[_vm._v(" Op basis van je transacties adviseren we de volgende producten. Bepaal zelf voor welke producten je een eerlijk aanbod wil ontvangen! ")]):_vm._e(),_c('div',{staticClass:"column"},_vm._l((_vm.transactionResponse.advice),function(insurance){return _c('div',{key:insurance.pid,staticClass:"block"},[_c('b-tooltip',{attrs:{"label":insurance.description,"position":"is-right"}},[_c('b-switch',{staticClass:"has-text-light",attrs:{"id":insurance.pid,"type":"is-link","passive-type":"is-light"},model:{value:(insurance.advice),callback:function ($$v) {_vm.$set(insurance, "advice", $$v)},expression:"insurance.advice"}},[_c('h2',{staticClass:"subtitle has-text-light"},[_vm._v(" "+_vm._s(insurance.name)+" ")])])],1)],1)}),0)]:_vm._e()],2):_vm._e(),(_vm.userflow || _vm.stepsDone.insuranceChoice)?[(_vm.userflow)?_c('h2',{staticClass:"title has-text-light"},[_vm._v(" Bepaal voor welke producten je een eerlijk aanbod wil ontvangen ")]):_vm._e(),(_vm.stepsDone.insuranceChoice)?_c('h2',{staticClass:"title has-text-light"},[_vm._v(" Overzicht van jouw gekozen producten ")]):_vm._e(),_vm._l((_vm.productsStatus),function(product){return _c('div',{key:product.pid,staticClass:"block"},[_c('b-tooltip',{attrs:{"label":product.description,"position":"is-right"}},[_c('b-switch',{staticClass:"has-text-light",attrs:{"id":product.pid,"type":"is-link","passive-type":"is-light"},model:{value:(product.userStatus.inInsuranceChoice),callback:function ($$v) {_vm.$set(product.userStatus, "inInsuranceChoice", $$v)},expression:"product.userStatus.inInsuranceChoice"}},[_c('h2',{staticClass:"subtitle has-text-light"},[_vm._v(" "+_vm._s(product.name)+" ")])])],1)],1)}),(!_vm.userflow)?_c('b-button',{attrs:{"type":{
          'is-medium': true,
          'is-primary': true,
          'is-link': _vm.transactionflow,
        },"icon-left":"sync-alt"},on:{"click":function($event){(_vm.transactionflow = true),
          (_vm.userflow = false),
          (_vm.stepsDone.insuranceChoice = ''),
          _vm.requestplaidaccesstoken()}}},[_vm._v(" Lees mijn transacties in en geef me advies ")]):_vm._e()]:_vm._e()],2),(_vm.transactionResponse && _vm.transactionflow)?_c('a',[_c('b-tooltip',{attrs:{"label":"Dit zijn mijn keuzes, ga naar mijn dashboard","position":"is-bottom","type":"is-primary"}},[_c('b-icon',{attrs:{"pack":"fas","icon":"chevron-down","size":"is-large","type":"is-light"},nativeOn:{"click":function($event){_vm.setToDownward(),
          _vm.updateregistration({
            step: 'insuranceChoice',
            registrationdata: {
              insuranceChoice: _vm.checkedProducts,
            },
          })}}})],1)],1):_vm._e(),((_vm.productsStatus && _vm.userflow) || _vm.stepsDone.insuranceChoice)?_c('a',[_c('b-tooltip',{attrs:{"type":"is-primary","label":"Dit zijn mijn keuzes, ga naar mijn dashboard","position":"is-bottom"}},[_c('b-icon',{attrs:{"pack":"fas","icon":"chevron-down","size":"is-large","type":"is-light"},nativeOn:{"click":function($event){_vm.setToDownward(),
          _vm.updateregistration({
            step: 'insuranceChoice',
            registrationdata: {
              insuranceChoice: _vm.checkedNonAdviceProducts,
            },
          })}}},[_vm._v(" checkedNonAdvicedProducts ")])],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }