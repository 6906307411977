<template>
  <div v-if="product">
    <b-progress
      type="is-link"
      size="is-small"
      :value="percent"
    />
    <div class="hero">
      <div class="hero-head">
        <p class="title has-text-centered has-text-white">
          <b-tag
                   
            type="is-link"
          >
            <b-icon :icon="product.icon" />  {{ product.name }}
          </b-tag>
        </p>
      </div>
      <div
        class="hero-body"
      >
        <div class="columns">
          <div class="column is-1 has-text-centered">
            <a v-if="steps[stepsIndex - 1]">
              <b-icon
                pack="fas"
                icon="chevron-up"
                size="is-large"
                type="is-light"
                @click.native="upwardRoute"
              />
              <!-- <p class="has-text-light">Ga naar de volgende stap</p>  -->
            </a>
          </div>
   
          <div class="column">
            <transition
              :name="transitionName"
              mode="out-in"
            >
              <!-- <keep-alive> -->
              <router-view v-if="regdata" />
              <!-- </keep-alive> -->
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import router from "../router";

export default {
  name: "Registration",
  components: {},
  provide() {
    return {
      downwardRoute: this.downwardRoute,
      upwardRoute: this.upwardRoute,
      setToDownward: this.setToDownward,
      nextRoute: this.nextRoute,
    };
  },
  data() {
    return {
      // transitionName: "slideDown",
    };
  },
  computed: {
    ...mapGetters([
      "genericloader",
      "errormessage",
      // "registrationSteps",
      //  "stepsIndexd",
      //  "registrationStatusData",
      "isAuthenticated",
      "uid",
      "products",
      "product",
      "transitionName",
      "regdata"
      // "transitionName"
    ]),
    currentPath() {
      return this.$route.path 
    },
    steps() {
      // console.log(this.product.routes)
      return this.product.routes;
    },
    stepsIndex() {
      return this.steps.indexOf(this.$route.path);
    },
    percent() {
      // console.log(this.stepsIndex)
      return (this.stepsIndex / this.steps.length) * 95 + 5
    },
  },
  async created() {
     
    if (this.isAuthenticated) {
      await this.getProductsStatus();
      await this.getRegistrationstatusData();

      if (!this.product) {
         this.setProduct(localStorage.getItem("pid"));
      }
    }
  },
  mounted: async function () {},
  
  methods: {
    ...mapActions([
    
      "getRegistrationstatusData",
      "setProduct",
      "getProductsStatus",
    ]),
    ...mapMutations([
      "setTransitionName"
    ]),
    //     shownextroute() {
    //       const steps = router.options.routes.find(x => x.name === 'registration').children.map(function(e) { return e.path; })
    //       const stepsIndex = steps.indexOf(this.$route.path.split("/").pop())
    //       const nextRoute = steps[stepsIndex + 1]
    //       router.push({path: nextRoute})
    // },
    // downwardRoute() {
    //   console.log(this.$route.path)
    //   this.transitionName = "slideDown";
    //   // console.log(this.nextRoute);
    //   const nextRoute = this.steps[this.stepsIndex + 1]
    //   console.log(nextRoute)
    //   router.push({ path: nextRoute });
    // },
    upwardRoute() {
      this.setTransitionName("slideUp")
      // this.transitionName = "slideUp";
      // console.log(this.previousRoute);
      const previousRoute = this.steps[this.stepsIndex - 1]
      // console.log(previousRoute)
      router.push({ path: previousRoute });
    },
    setToDownward() {
      this.transitionName = "slideDown";
    },
  },

  //  watch: {
  //   // whenever errormessage changes, this function will run
  //   errormessage: function () {
  //     // console.log(this.errormessage)
  //      this.$buefy.snackbar.open(this.errormessage)
  //             // this.$buefy.toast.open( this.errormessage)

  //   }
  // },
};
</script>

<style lang="scss" scoped>
///////////////////////////// slide up animation /////////////////////////////

.slideUp-enter-active {
  animation: slide-in-up 300ms ease-out forwards;
}

.slideUp-leave-active {
  animation: slide-out-up 300ms ease-out backwards;
}

@keyframes slide-in-up {
  from {
    transform: translateY(-40px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out-up {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(40px);
    opacity: 0;
  }
}

///////////////////////////// slide down animation /////////////////////////////

.slideDown-enter-active {
  animation: slide-in-down 300ms ease-out forwards;
}

.slideDown-leave-active {
  animation: slide-out-down 300ms ease-out backwards;
}

@keyframes slide-in-down {
  from {
    transform: translateY(40px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out-down {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-40px);
    opacity: 0;
  }
}
</style>