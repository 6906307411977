<template
  v-if="isAuthenticated"
>
  <div>
    <div class="hero">
      <div class="hero-body">
        <h1 class="title has-text-light">
          Mijn dashboard
        </h1>

        <div class="columns">
          <div class="column is-4">
            <Cardslot>
              <template #iconheader>
                <b-icon
                  icon="shoe-prints"
                  size="is-large"
                  type="is-light"
                />
              </template>
              <template #header>
                Af te ronden aanvragen
              </template>
              <template #tagheader>
                <b-tag
                  v-if="productsStatus"
                  type="is-primary"
                >
                  {{
                    productsStatus.filter((product) => {
                      return (
                        product.userStatus.eligibleToStartAuction ||
                        product.userStatus.status == "Registration"
                      );
                    }).length
                  }}
                </b-tag>
              </template>
              <template #content>
                <div
                  v-if="productsStatus"
                  class="columns is-multiline"
                >
                  <div
                    v-for="product in productsStatus.filter((product) => {
                      return (
                        product.userStatus.eligibleToStartAuction ||
                        product.userStatus.status == 'Registration'
                      );
                    })"
                    :key="product.pid"
                    class="column is-6"
                  >
                    <a @click="setPid(product.pid)">
                      <div
                        class="card mouseover"
                        :class="{ adviced: product.userStatus.insuranceAdvice }"
                      >
                        <header class="card-header">
                          <p
                            class="card-header-title is-centered has-text-light"
                          >
                            {{ product.name }}
                            <!-- <b-tag
                              v-if="productsStatus"
                              type="is-primary"
                              size="is-small"
                            >
                              ons advies!
                            </b-tag> -->
                          </p>
                       
                        
             
                        </header>
                        <div class="card-content">
                          <div class="content has-text-centered">
                            <div class="columns is-multiline">
                              <div class="column is-12">
                                <b-icon
                                  type="is-light"
                                  size="is-medium"
                                  :icon="product.icon"
                                />
                                <!-- <b-button
                                  type="is-primary"
                                  size="is-small"
                                >
                                  Rond af
                                </b-button> -->
                              </div>
                              <div class="column is-12">
                                <b-progress
                                  :value="
                                    product.requiredRegistrationSteps.length -
                                      (product.incompleteRegistrationSteps
                                        .length +
                                        1)
                                  "
                                  :max="
                                    product.requiredRegistrationSteps.length
                                  "
                                  type="is-link"
                                  size="is-small"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <!-- <a
                  class="box"
                  @click="requestplaidaccesstoken()"
                >
                  <p class="has-text-centered has-text-light is-italic">
                    Lees mijn transacties uit en adviseer!
                  </p>  
                </a> -->
                <b-button
                  expanded
                  type="is-primary"
                  size="is-small"
                  icon-left="sync-alt"
                  @click="requestplaidaccesstoken()"
                >
                  Lees mijn transacties uit en adviseer!
                </b-button>
              </template>
              <template #footer>
                {{ requesttext }}
              </template>
            </Cardslot>
          </div>
          <div class="column is-4">
            <Cardslot>
              <template #iconheader>
                <b-icon
                  icon="gavel"
                  size="is-large"
                  type="is-light"
                />
              </template>
              <template #header>
                Actieve veilingen
              </template>
              <template #tagheader>
                <b-tag
                  v-if="productsStatus"
                  type="is-primary"
                >
                  {{
                    productsStatus.filter((product) => {
                      return product.auction.running;
                    }).length
                  }}
                </b-tag>
              </template>
              <template #content>
                <div
                  v-if="productsStatus"
                  class="columns is-multiline"
                >
                  <div
                    v-for="product in productsStatus.filter((product) => {
                      if (
                        Math.floor(
                          (new Date() - new Date(product.auction.auctionEnd)) /
                            1000 /
                            60 /
                            60 /
                            24
                        ) < 2
                      ) {
                        return product;
                      }
                    })"
                    :key="product.pid"
                    class="column is-12"
                  >
                    <!-- <div class="column is-12"> -->
                    <!-- {{ Math.floor((new Date() - new Date(request.auctionEnd))/1000/60/60/24) > 2 }} -->
                    <div
                      class="box"
                      :class="{
                        opacity:
                          new Date() - new Date(product.auction.auctionEnd) > 0,
                        adviced: product.userStatus.insuranceAdvice,
                      }"
                    >
                      <div class="columns is-vcentered">
                        <div class="column has-text-centered">
                          <h1 class="subtitle has-text-light">
                            {{ product.name }}
                          </h1>
                          <b-tag
                            class=""
                            type="is-link"
                          >
                            0 bieding(en)
                          </b-tag>
                        </div>
                        <div class="column is-flex has-text-centered">
                          <!-- <h1 class="subtitle has-text-light">
                        {{ request.product.name }}
                      </h1> -->
                          <!-- <baseTimer
                        :timelimit="3600"
                        :time-left-prop="800"
                        product="hoi"
                      /> -->
                          <!-- {{ new Date(product.auction.crton) }} -->
                          <!-- {{ Math.floor(new Date(product.auction.auctionEnd) - new Date(product.auction.crton)) / 1000 }} -->
                          <!-- {{ product.auction.auctionEnd }} <br> -->
                          <!-- {{ product.auction.crton }} <br> -->
                          <auctionTimer
                            :time-left-prop="
                              auctionTimeLeft(product.auction.auctionEnd)
                            "
                            :timelimit="
                              auctionBiddingTime(
                                product.auction.auctionEnd,
                                product.auction.crton
                              )
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #footer>
                {{ auctiontext }}
              </template>
            </Cardslot>
          </div>
          <div class="column is-4">
            <Cardslot>
              <template #iconheader>
                <b-icon
                  icon="building"
                  size="is-large"
                  type="is-light"
                />
              </template>
              <template #header>
                Lopende verzekeringen
              </template>

              <template #tagheader>
                <b-tag
                  v-if="productsStatus"
                  type="is-primary"
                >
                  {{
                    productsStatus.filter((product) => {
                      return product.userStatus.status == "Insurance in effect";
                    }).length
                  }}
                </b-tag>
              </template>

              <template #content>
                <div
                  v-if="productsStatus"
                  class="columns is-multiline"
                >
                  <div
                    v-for="product in productsStatus.filter((product) => {
                      return product.userStatus.status == 'Insurance in effect';
                    })"
                    :key="product.pid"
                    class="column is-6"
                  >
                    <div class="card">
                      <header class="card-header">
                        <p class="card-header-title is-centered has-text-light">
                          NN - {{ product.name }}
                        </p>
                      </header>
                      <div class="card-content">
                        <div class="content has-text-centered">
                          <div class="columns is-multiline">
                            <div class="column is-12">
                              <b-icon
                                type="is-light"
                                :icon="product.icon"
                                size="is-medium"
                              />
                              <!-- <b-button
                                size="is-small"
                                type="is-primary"
                              >
                                info
                              </b-button> -->
                            </div>
                            <div class="column is-12">
                              <b-progress
                                :value="10"
                                :max="100"
                                type="is-link"
                                size="is-small"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template #footer>
                {{ insurancetext }}
              </template>
            </Cardslot>
          </div>
        </div>
        <p class="icon-text has-text-left">
          <span class="icon has-text-light">
            <i class="far fa-square" />
          </span>
          <span
            class=""
          >= ons advies op basis van je profiel of transacties</span>
        </p>
      </div>
    </div>
  </div>
</template> 

<script>
import { mapGetters, mapActions } from "vuex";
import auctionTimer from "../components/shared/auctiontimer";
import Cardslot from "../components/shared/cardslot";
// import router from "../router";

export default {
  name: "Dashboard",
  components: {
    auctionTimer,
    Cardslot,
  },
  data() {
    return {
      hover: false,
      showClientComponent: false,
      requesttext:
        "Onze beschikbare verzekeringen, laat je adviseren en rond af",
      auctiontext: "Check de status van je veiling en hou de timer in de gaten",
      insurancetext:
        "Al jouw verzekeringen en bijbehorende looptijd op 1 plek!",
    };
  },
  computed: {
    ...mapGetters([
      // "requests",
      "uid",
      "genericloader",
      "personalData",
      "stepsMapping",
      // "insuranceChoice",
      "stepsDone",
      // "products",
      "productsStatus",
    ]),
    // filteredRequests() {
    //   return this.requests.filter((request) => {
    //     if (new Date(request.auctionEnd) - new Date() < 0) return request;
    //   });
    // },
  },
  created: async function () {
    // await this.getRequests();
    await this.getProductsStatus();
  },
  mounted: async function () {
    this.dashboardAlert();

    // this.$buefy.toast.open({ message:  "Welkom op jouw dashboard! <br> Check hier je af te ronden aanvragen, je actieve veilingen en je lopende verzekeringen", type: "is-link" })
    // this.getRegistrationstatusData();
    //  await this.getProductsStatus()
  },

  methods: {
    ...mapActions([
      // "getRequests",
      // "tryAutoLogin",
      "getPersonaldata",
      // "getRegistrationstatusData",
      "getProductsStatus",
      "setPid",
      "requestplaidaccesstoken",
    ]),
    dashboardAlert() {
      if (!localStorage.getItem("dashboardalert")) {
        this.$buefy.dialog.confirm({
          title: "Welkom op jouw dashboard!",
          message:
            "<ul> <li> <b> Af te ronden aanvragen </b> <br>" +
            this.requesttext +
            "</li> <li> <b>Actieve veilingen </b> <br>" +
            this.auctiontext +
            "</li><li> <b>Lopende verzekeringen </b> <br>" +
            this.insurancetext +
            "</li></ul>",
          confirmText: "Cool!",
          cancelText: "Begrepen, laat dit bericht niet meer zien",
          // onConfirm: () =>  localStorage.setItem("showdashboardAlert", false),
          onCancel: () => localStorage.setItem("dashboardalert", true),
          // hasIcon: true,
          // icon: "tachometer-alt",
          // onConfirm: () =>
          //   this.$buefy.toast.open({ message: "Succes", type: "is-link" }),
        });
      }
    },
    auctionTimeLeft(end) {
      const today = new Date();
      const auctionEnd = new Date(end);
      return Math.floor((auctionEnd - today) / 1000);
    },
    auctionBiddingTime(end, start) {
      return Math.floor((new Date(end) - new Date(start)) / 1000);
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/variables.scss";

.box {
  height: 100%;
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card-content {
  flex: auto;
}

.adviced {
  border-width: 1px;
  border-style: solid;
  border-color: $light;
}

// .giveadvice {
//   border-width: 0.1px;
//   border-style: solid;
//   border-color: $link;
// }

/* .column {
    height: 90%
  } */

/* p {
    color: white
  } */

/* .child {
  height: max-content;
} */

/* .parent {
  display: flex
} */

// hr {
//   color: white;
//   height: 1px;
//   clear: both;
//   display: block;
// }

/* .columns:not(:last-child) {
  margin-bottom: -0.75rem;
} */

p {
  font-size: 0.8em;
}

.opacity {
  opacity: 0.3;
}
</style>