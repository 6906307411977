<template>
  <b-field label="Search by course:">
    <input
      v-model="courseId"
      placeholder="Select a course"
     
      @change="updateCourseFilter()"
    >
    <span>hoi</span>
    <!-- <option v-for="opt in courses" :value="opt">{{ opt }}</option> -->
  </b-field>
</template>

<script>
export default {
    data: () => {
      return {
        courseId: 'engels'
      }
    },
  // created() {
  //   var intval = setInterval(() => {
  //     if(this.percentage < 100)
  //       this.percentage += 0.2;
  //     else
  //       clearInterval(intval);
  //   }, 10);
  // },
  methods: {
    updateCourseFilter() {
              console.log('het werkt!')
            }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

.loading-bar {
    position: relative;
    width: 400px;
    height: 30px;
    border-radius: 15px;
    overflow: hidden;
    border: 1px solid $green;
}

  .percentage {
    position: absolute;
    // top: 1px; left: 1px; right: 1px;
    display: block;
    height: 100%;
    border-radius: 15px;
    background-color: $green;
    background-size: 30px 30px;
    // background-image: linear-gradient(135deg, rgba($color: white, $alpha: .15) 25%, transparent 25%,
    //   transparent 50%, rgba($color: white, $alpha: .15) 50%,
    //   rgba($color: white, $alpha: .15) 75%, transparent 75%,
    //   transparent); 
    animation: animate-stripes 3s linear infinite;
  } 

@keyframes animate-stripes {
  0% {background-position: 0 0; }
  100% { background-position: 60px 0;}
}
</style>