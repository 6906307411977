var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"column is-half"},[_c('h2',{staticClass:"subtitle has-text-light"},[_vm._v(" Autoregistratie ")]),_c('h1',{staticClass:"title has-text-light"},[_vm._v(" Wat is je kenteken? ")]),_c('ValidationObserver',{staticClass:"box"},[_c('form',{on:{"submit":function($event){$event.preventDefault();_vm.updateregistration({
            step: 'licenseplate',
            registrationdata: {
              licenseplate: _vm.cardata.kenteken
                .toUpperCase()
                .replace(/[-/_]/g, ''),
            },
          })}}},[_c('BInputWithValidation',{attrs:{"vid":"any","name":"Kenteken","rules":"required","type":"input","label":"Kenteken","icon":"car","buttontext":"Verzenden","buttonicon":"paper-plane","expanded-input":""},model:{value:(_vm.cardata.kenteken),callback:function ($$v) {_vm.$set(_vm.cardata, "kenteken", $$v)},expression:"cardata.kenteken"}})],1)]),(_vm.stepsDone.licenseplate)?[_c('h1',{staticClass:"title has-text-light"},[_vm._v(" Jouw autogegevens ")]),_c('div',{staticClass:"box"},_vm._l((_vm.relevantcardata),function(value,name){return _c('div',{key:name,staticClass:"has-text-light"},[(name != 'Kenteken')?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column has-text-left"},[_vm._v(" "+_vm._s(name)+": ")]),_c('div',{staticClass:"column has-text-right"},[_vm._v(" "+_vm._s(value)+" ")])]):_vm._e()])}),0),_c('a',{on:{"click":function($event){return _vm.toNextRoute(_vm.$route.path)}}},[_c('b-icon',{attrs:{"pack":"fas","icon":"chevron-down","size":"is-large","type":"is-light"}}),_c('span',{staticClass:"has-text-light"},[_vm._v("Dit is mijn auto")])],1)]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }