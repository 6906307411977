<template>
  <div>
    <section class="hero is-fullheight-with-navbar has-background">
      <img
        alt="bg"
        class="hero-background is-transparent"
        src="../assets/bg-house.jpg"
      >
      <div class="hero-body "> 
        <div class="container has-text-centered ">
          <h1 class="title has-text-light is-spaced is-large-title">
            INVOLUTE
          </h1>
          <h3 class="subtitle has-text-light is-5">
            Veil jouw situatie en ontvang jaarlijks het scherpste aanbod voor al je verzekeringen
          </h3> <br>
          <b-button
            v-if="!isAuthenticated"
            tag="router-link"
            to="preregistration/emailregistration"
            class="button is-link block"
            icon-left="play"
          >
            Start aanvraag
          </b-button>
          <b-button
            v-if="isAuthenticated"
            tag="router-link"
            to="/dashboard"
            class="button is-link block"
            icon-left="tachometer-alt"
          >
            Ga naar mijn dashboard
          </b-button>
          <br>
          <br>
          <br>
          <div class="div">
            <div class="columns">
              <div class="column">
                <b-icon
                  icon="thumbs-up"
                  size="is-large"
                  type="is-light"
                /> <br> <br>
                <p class="subtitle has-text-light">
                  Eenvoudig
                </p>
              </div>
              <div class="column">
                <b-icon
                  icon="eye"
                  size="is-large"
                  type="is-light"
                /> <br> <br>
                <p class="subtitle has-text-light">
                  Transparant
                </p>
              </div>
              <div class="column">
                <b-icon
                  icon="magic"
                  size="is-large"
                  type="is-light"
                /> <br> <br>
                <p class="subtitle has-text-light">
                  Automatisch besparen
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-medium">
      <div class="hero-body">
        <nav class="level has-text-light">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">
                # aangesloten klanten
              </p>
              <p class="title has-text-light">
                3,456
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">
                Gemiddelde jaarlijks besparing:
              </p>
              <p class="title has-text-light">
                123
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">
                # veilingen:
              </p>
              <p class="title has-text-light">
                456K
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">
                # aangesloten verzekeraars
              </p>
              <p class="title has-text-light">
                789
              </p>
            </div>
          </div>
        </nav>
      </div>
    </section>
  </div>
  <!-- <div class="">
    <section
      id="hero"
      class="hero is-large background is-fullheight-with-navbar"
    >
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="has-text-light is-size-1 is-size-3-mobile">
            INVOLUTE
          </h1>
          <br>
          <div class="block">
            <b-button
              v-if="!isAuthenticated"
              tag="router-link"
              to="preregistration/emailregistration"
              class="button is-link block"
              icon-left="play"
            >
              Start aanvraag
            </b-button>
          </div>

          <div class="block">
            <b-button
              v-if="isAuthenticated"
              tag="router-link"
              to="/dashboard"
              class="button is-link block"
              icon-left="tachometer-alt"
            >
              Ga naar jouw dashboard
            </b-button>
          </div>
        </div>
      </div>
    </section>
  
  </div> -->
</template>


<script>
import { mapGetters } from "vuex";


export default {
  name: "Home",
  components: {},
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";


.hero.has-background {
  position: relative;
  overflow: hidden;
}
.hero-background {
  position: absolute;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}
.hero-background.is-transparent {
  opacity: 0.8;
}

.is-large-title {
  font-size: 5rem;
}




// .background {
//   background-image: url("../assets/bg-house.jpg");
//   background-position: center;
//   background-size: cover;
// }
</style>