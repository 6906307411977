<template>
  <div id="app">
    <Loader />
    <Navbar />
    <router-view />
  </div>
</template>



<script>
import Navbar from "./components/shared/navbar";
import Loader from "./components/shared/loader";
import { mapActions } from "vuex";

export default {
  name: "App",
  components: {
    Navbar,
    Loader,
  },
  data() {
    return {
      paraIsVisible: true,
    };
  },
  created() {
   this.tryAutoLogin();

  },
  mounted: function () {
  },
  methods: {
    ...mapActions(["tryAutoLogin"]),

  },
};
</script>

<style lang="scss">
@import "./assets/scss/variables.scss";
@import "./assets/scss/stylesheet.scss"

</style>
