import axios from "axios"
// import { indexOf } from "core-js/core/array"
import router from "../router"

const url = "/realm/user/dashboard"

export default {
    state: {
        personalData: null,
        requests: null,
        startAuction: false,
        productsStatus: null,
        product: null,
        pid: null,
        pidRoutes: []
    },
    getters: {
        requests(state) {
            return state.requests
        },
        personalData(state) {
            return state.personalData
        },
        startAuction(state) {
            return state.startAuction
        },
        productsStatus(state) {
            return state.productsStatus
        },
        product(state) {
            return state.product
        }

    },
    mutations: {
        saverequests(state, payload) {
            state.requests = null
            state.requests = payload.requests
            // state.showrequestsplaceholder = payload.showrequestsplaceholder
        },
        savePersonalData(state, payload) {
            state.personalData = payload
        },
        toggleAuction(state, payload) {
            state.startAuction = payload
        },
        saveProductsStatus(state, payload) {
            state.productsStatus = payload
        },
        setPid(state, payload) {
            state.pid = payload
        },
        saveProduct(state, payload) {
            state.product = payload
        },
},
    actions: {
        async getRequests(context) {

            let userResponse

            try {
                userResponse = await axios.get(url + "/requests")

                if (userResponse.status == 200 && userResponse.data.data[0]) {
                    context.commit("saverequests", {
                        requests: userResponse.data.data
                        // showrequestsplaceholder: false
                        // offers: offers
                        // userId: userId
                    })
                }
            } catch (error) {
                // define specific error handling actions here
            }
        },

        async getPersonaldata(context) {
            let userResponse
            try {
                userResponse = await axios.get(url + "/personaldata"

                )
                if (userResponse.status == 200) {
                    context.commit("savePersonalData", userResponse.data.data)
                }
            } catch (error) {
                // define specific error handling actions here
            }
        },
        async postRegistration(context, pid) {
            // console.log('1')
            context.commit("toggleAuction", true)

            let requestResponse

            try {
                // console.log(pid)
                // await new Promise(resolve => setTimeout(resolve, 5000))
                requestResponse = await axios.post(url + "/request/from/products", { products: pid }, { headers: { noLoader: true } })

                if (requestResponse.status == 200) {
                    context.commit("toggleAuction", false)

                    //maak hier een mooie visual zodat gebruiker ziet dat er een veiling wordt opgestart
                    router.push("/dashboard")
                }
            } catch (error) {
                context.commit("toggleAuction", false)
                // define specific error handling actions here
            }
        },

        async getProductsStatus(context) {
            try {
                let productResponse = await axios.get(url + "/products")

                if (productResponse.status == 200 && productResponse.data.data[0]) {

                    context.commit("saveProductsStatus", productResponse.data.data)

                }

            } catch (error) {
                // define specific error handling actions here
            }
        },

        async setPid(context, pid) {

            localStorage.setItem("pid", pid);
            await context.commit("setPid", pid);
            await context.dispatch("setProduct", pid);
            await context.dispatch("toFirstRoute")

        },

        async setProduct(context, pid) {

            if (!context.state.productsStatus) {
                await context.dispatch("getProductsStatus")
            }

            const product = await context.state.productsStatus.reduce(async function (reduced, route) {
                if (route.pid == pid) {
                    reduced = route
                    reduced.routes = await context.dispatch("stepsToRoutes", route.requiredRegistrationSteps)
                }
                return reduced;
            }, []);

            context.commit("saveProduct", product)
            // context.commit("saveRegProducts")

        },

        // openRegistrations() {
        //     let status = this.productsStatus
        //     console.log(status)

        //     const registrations = status.reduce(function (acc, currVal) {
        //               if (currVal.userStatus.inInsuranceChoice) {
        //                   acc == currVal

        //               }
        //               return acc;
        //           }, []);

        //     return registrations
        //     // return 'hoi'
        //   }

    }
}
