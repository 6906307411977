var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"hero"},[_c('div',{staticClass:"hero-body"},[_c('h1',{staticClass:"title has-text-light"},[_vm._v(" Mijn dashboard ")]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('Cardslot',{scopedSlots:_vm._u([{key:"iconheader",fn:function(){return [_c('b-icon',{attrs:{"icon":"shoe-prints","size":"is-large","type":"is-light"}})]},proxy:true},{key:"header",fn:function(){return [_vm._v(" Af te ronden aanvragen ")]},proxy:true},{key:"tagheader",fn:function(){return [(_vm.productsStatus)?_c('b-tag',{attrs:{"type":"is-primary"}},[_vm._v(" "+_vm._s(_vm.productsStatus.filter(function (product) { return ( product.userStatus.eligibleToStartAuction || product.userStatus.status == "Registration" ); }).length)+" ")]):_vm._e()]},proxy:true},{key:"content",fn:function(){return [(_vm.productsStatus)?_c('div',{staticClass:"columns is-multiline"},_vm._l((_vm.productsStatus.filter(function (product) {
                    return (
                      product.userStatus.eligibleToStartAuction ||
                      product.userStatus.status == 'Registration'
                    );
                  })),function(product){return _c('div',{key:product.pid,staticClass:"column is-6"},[_c('a',{on:{"click":function($event){return _vm.setPid(product.pid)}}},[_c('div',{staticClass:"card mouseover",class:{ adviced: product.userStatus.insuranceAdvice }},[_c('header',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title is-centered has-text-light"},[_vm._v(" "+_vm._s(product.name)+" ")])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content has-text-centered"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('b-icon',{attrs:{"type":"is-light","size":"is-medium","icon":product.icon}})],1),_c('div',{staticClass:"column is-12"},[_c('b-progress',{attrs:{"value":product.requiredRegistrationSteps.length -
                                    (product.incompleteRegistrationSteps
                                      .length +
                                      1),"max":product.requiredRegistrationSteps.length,"type":"is-link","size":"is-small"}})],1)])])])])])])}),0):_vm._e(),_c('b-button',{attrs:{"expanded":"","type":"is-primary","size":"is-small","icon-left":"sync-alt"},on:{"click":function($event){return _vm.requestplaidaccesstoken()}}},[_vm._v(" Lees mijn transacties uit en adviseer! ")])]},proxy:true},{key:"footer",fn:function(){return [_vm._v(" "+_vm._s(_vm.requesttext)+" ")]},proxy:true}])})],1),_c('div',{staticClass:"column is-4"},[_c('Cardslot',{scopedSlots:_vm._u([{key:"iconheader",fn:function(){return [_c('b-icon',{attrs:{"icon":"gavel","size":"is-large","type":"is-light"}})]},proxy:true},{key:"header",fn:function(){return [_vm._v(" Actieve veilingen ")]},proxy:true},{key:"tagheader",fn:function(){return [(_vm.productsStatus)?_c('b-tag',{attrs:{"type":"is-primary"}},[_vm._v(" "+_vm._s(_vm.productsStatus.filter(function (product) { return product.auction.running; }).length)+" ")]):_vm._e()]},proxy:true},{key:"content",fn:function(){return [(_vm.productsStatus)?_c('div',{staticClass:"columns is-multiline"},_vm._l((_vm.productsStatus.filter(function (product) {
                    if (
                      Math.floor(
                        (new Date() - new Date(product.auction.auctionEnd)) /
                          1000 /
                          60 /
                          60 /
                          24
                      ) < 2
                    ) {
                      return product;
                    }
                  })),function(product){return _c('div',{key:product.pid,staticClass:"column is-12"},[_c('div',{staticClass:"box",class:{
                      opacity:
                        new Date() - new Date(product.auction.auctionEnd) > 0,
                      adviced: product.userStatus.insuranceAdvice,
                    }},[_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column has-text-centered"},[_c('h1',{staticClass:"subtitle has-text-light"},[_vm._v(" "+_vm._s(product.name)+" ")]),_c('b-tag',{attrs:{"type":"is-link"}},[_vm._v(" 0 bieding(en) ")])],1),_c('div',{staticClass:"column is-flex has-text-centered"},[_c('auctionTimer',{attrs:{"time-left-prop":_vm.auctionTimeLeft(product.auction.auctionEnd),"timelimit":_vm.auctionBiddingTime(
                              product.auction.auctionEnd,
                              product.auction.crton
                            )}})],1)])])])}),0):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_vm._v(" "+_vm._s(_vm.auctiontext)+" ")]},proxy:true}])})],1),_c('div',{staticClass:"column is-4"},[_c('Cardslot',{scopedSlots:_vm._u([{key:"iconheader",fn:function(){return [_c('b-icon',{attrs:{"icon":"building","size":"is-large","type":"is-light"}})]},proxy:true},{key:"header",fn:function(){return [_vm._v(" Lopende verzekeringen ")]},proxy:true},{key:"tagheader",fn:function(){return [(_vm.productsStatus)?_c('b-tag',{attrs:{"type":"is-primary"}},[_vm._v(" "+_vm._s(_vm.productsStatus.filter(function (product) { return product.userStatus.status == "Insurance in effect"; }).length)+" ")]):_vm._e()]},proxy:true},{key:"content",fn:function(){return [(_vm.productsStatus)?_c('div',{staticClass:"columns is-multiline"},_vm._l((_vm.productsStatus.filter(function (product) {
                    return product.userStatus.status == 'Insurance in effect';
                  })),function(product){return _c('div',{key:product.pid,staticClass:"column is-6"},[_c('div',{staticClass:"card"},[_c('header',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title is-centered has-text-light"},[_vm._v(" NN - "+_vm._s(product.name)+" ")])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content has-text-centered"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('b-icon',{attrs:{"type":"is-light","icon":product.icon,"size":"is-medium"}})],1),_c('div',{staticClass:"column is-12"},[_c('b-progress',{attrs:{"value":10,"max":100,"type":"is-link","size":"is-small"}})],1)])])])])])}),0):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_vm._v(" "+_vm._s(_vm.insurancetext)+" ")]},proxy:true}])})],1)]),_vm._m(0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"icon-text has-text-left"},[_c('span',{staticClass:"icon has-text-light"},[_c('i',{staticClass:"far fa-square"})]),_c('span',{},[_vm._v("= ons advies op basis van je profiel of transacties")])])}]

export { render, staticRenderFns }