<template>
  <div
    class="container"
  >
    <!-- <a>
      <b-icon
        @click.native="upwardRoute"
        pack="fas"
        icon="chevron-up"
        size="is-large"
        type="is-light"
      >
      </b-icon>
    </a> -->
    <h2 class="subtitle has-text-light">
      Gezinsregistratie
    </h2>

    <h1 class="title has-text-light">
      Wat is je gezinssituatie?
    </h1>
    <div class="columns is-multiline">
      <div
        v-for="family in familyOptions"
        :key="family.key"
        class="column has-text-centered"
      >
        <a @click="familyComposition.family = family.key">
          <div
            class="box mouseover"
            :class="{ green: family.key == familyComposition.family }"
          >
            <div class="column has-text-light">
              {{ family.name }}
            </div>
            <div class="column">
              <b-icon
                type="is-light"
                size="is-large"
                :icon="family.icon"
              />
            </div>
          </div>
        </a>
      </div>
    </div>
    <h1 class="title has-text-light">
      Woon je in een huur- of koophuis?
    </h1>
    <div class="columns is-multiline">
      <div
        v-for="house in ownerOptions"
        :key="house.key"
        class="column has-text-centered"
      >
        <a @click="familyComposition.owner = house.key">
          <div
            :class="{ green: house.key == familyComposition.owner }"
            class="box mouseover"
          >
            <div class="column has-text-light">
              {{ house.name }}
            </div>
            <div class="column">
              <b-icon
                type="is-light"
                size="is-large"
                :icon="house.icon"
              />
            </div>
          </div>
        </a>
      </div>
    </div>
    <h1 class="title has-text-light">
      Heb je huisdieren?
    </h1>
    <div class="columns is-multiline">
      <div
        v-for="pet in petOptions"
        :key="pet.key"
        class="column has-text-centered"
      >
        <a @click="familyComposition.pet = pet.key">
          <div
            class="box mouseover"
            :class="{ green: pet.key == familyComposition.pet }"
          >
            <div class="column has-text-light">
              {{ pet.name }}
            </div>
            <div class="column">
              <b-icon
                type="is-light"
                size="is-large"
                :icon="pet.icon"
              />
            </div>
          </div>
        </a>
      </div>
    </div>
    <a
      class="has-text-centered"
      @click="
        updateregistration({
          step: 'familyComposition',
          registrationdata: {
            family: familyComposition.family,
            owner: familyComposition.owner,
            pet: familyComposition.pet,
          },
        })
      "
    >
      <b-icon
        pack="fas"
        icon="chevron-down"
        size="is-large"
        type="is-light"
      >
        />
      </b-icon></a>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Familyregistration",
  inject: ["downwardRoute"],
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters([
      "familyOptions",
      "petOptions",
      "ownerOptions",
      "familyComposition",
    ]),
  },
  async created() {
    this.getRegistrationstatusData()
  },
  async mounted() {
    
    // console.log(this.familyComposition)
    // if (this.familyComposition) {
    //   this.petChoice = this.familyComposition.pet;
    //   this.familyChoice = this.familyComposition.family;
    //   this.ownerChoice = this.familyComposition.owner;
    // } else {
    //   console.log('hier kom ik')
    //   this.familyChoice = "alone"
    //   this.ownerChoice = "rental"
    //   this.petChoice = "dog"
    // }
    
  },
  methods: {
    ...mapActions(["updateregistration", "getRegistrationstatusData"]),
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";

.green {
  border-width: 1px;
  border-style: solid;
  border-color: $green;
}
</style>