<template>
  <div class="container">
    <h2 class="subtitle has-text-light">
      Accountregistratie
    </h2>
    <transition
      name="slideUp"
      mode="out-in"
    >
      <h1
        v-if="!isAuthenticated"
        class="title has-text-light"
      >
        Vul je email in je ontvangt een verificatiecode
      </h1>
    </transition>
    <transition
      name="slideUp"
      mode="out-in"
    >
      <!-- <button @click="setErrors()">set errors</button> -->
      <ValidationObserver
        v-if="!isAuthenticated"
        ref="observer"
        class="column box is-half"
      >
        <form @submit.prevent="submit('emailaddress')">
          <fieldset :disabled="stepsDone.emailaddress">
            <BInputWithValidation
              v-model.trim="registrationClientData.emailaddress"
              vid="any"
              rules="required|email"
              type="email"
              label="Email"
              icon="envelope"
              buttontext="Verzenden"
              buttonicon="paper-plane"
              expanded-input
              :success="stepsDone.emailaddress"
            />
          </fieldset>
        </form>

        <form
          v-if="emailaddressSuccess"
          @submit.prevent="submit('emailaddressVerification')"
        >
          <fieldset :disabled="stepsDone.emailaddressVerification">
            <BInputWithValidation
              v-model.trim="registrationClientData.verificationToken"
              vid="any"
              rules="required"
              type="password"
              label="Verificatiecode"
              icon="check"
              buttontext="Verzenden"
              buttonicon="paper-plane"
              expanded-input
              nopositive-f-b
              :success="stepsDone.emailaddressVerification"
            />
          </fieldset>
        </form>
      </ValidationObserver>
    </transition>
    <div
      v-if="isAuthenticated"
      class=""
    >
      <h1 class="title has-text-light">
        Maak een wachtwoord aan of ga gelijk door naar de
        volgende stap
      </h1>

      <!-- <button @click="setErrors()">set errors</button> -->
      <Passwordregistration />
      <!-- <ValidationObserver
          ref="observer"
          class="box"
        >
          <form
            @submit.prevent="
              updateregistration({
                step: 'password',
                registrationdata: {
                  password: registrationClientData.password,
                },
              })
            "
          >
            <fieldset :disabled="stepsDone.password">
              <BInputWithValidation
                v-model.trim="registrationClientData.password"
                vid="password"
                name="password"
                rules="required"
                type="password"
                label="Wachtwoord"
                icon="key"
                :success="stepsDone.password"
                password-reveal
              />
            </fieldset>

            <fieldset :disabled="stepsDone.password">
              <BInputWithValidation
                v-model.trim="registrationClientData.repeatPassword"
                vid="any"
                rules="required|confirmed:password"
                type="password"
                label="Herhaal wachtwoord"
                icon="key"
                buttontext="Verzenden"
                :success="stepsDone.password"
                expanded-input
              />
            </fieldset>
          </form>
        </ValidationObserver> -->
      <!-- <h1 class="title has-text-light" v-if="stepsDone.password">
          Super! klik op het pijltje om door te gaan
        </h1> -->

      <!-- <router-link to="/registration/transactionregistration">
          <b-icon
            pack="fas"
            icon="chevron-down"
            size="is-large"
            type="is-light"
          >
          </b-icon>
          <br />
          <span class="has-text-light">Ga snel door!</span>
        </router-link> -->

      <!-- <h1 v-if="stepsDone.password && stepsDone.emailaddress" class="title has-text-light">Gelukt! Klik op de pijl linksonder en ga snel door naar de volgende stap</h1> -->
      <a
        v-if="isAuthenticated"
        class="has-text-centered"
      >
        <b-icon
          pack="fas"
          icon="chevron-down"
          size="is-large"
          type="is-light"
          @click.native="downwardRoute"
        >
          />
        </b-icon></a>
    </div>
    <!-- <a v-if="isAuthenticated">
      <b-icon
        pack="fas"
        icon="chevron-down"
        size="is-large"
        type="is-light"
        @click.native="downwardRoute"
      />
    </a> -->
  </div>
</template>
 


<script>
// import axios from 'axios'
import { mapActions, mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";
import BInputWithValidation from "../../components/inputs/BInputWithValidation";
import Passwordregistration from "./passwordregistration"
// import router from "../../router"

// import registrationN from "../../pages/registrationN";
// import Loader from '../loader.vue';

export default {
  name: "Emailregistration",
  components: {
    ValidationObserver,
    BInputWithValidation,
    Passwordregistration,
    // registrationN,
    // Loader
  },
  // props: {
  //   nextRoute: String,
  // },
  inject: ["downwardRoute"],
  data() {
    return {
      open: true,
      isloading: true,
      fullpage: false,
      showmodal: false,
    };
  },
  computed: {
    ...mapGetters([
      "stepsDone",
      "genericloader",
      "errormessage",
      "isAuthenticated",
      "registrationClientData",
      "emailaddressSuccess",
    ]),
  },
  watch: {
    errormessage() {
      this.$refs.observer.setErrors({
        any: [this.errormessage],
      });
    },
  },
  created: function () {},
  // mounted: function () {
  //   if (
  //     this.stepsDone.emailaddress &&
  //     this.stepsDone.emailaddressVerification
  //   ) {
  //     this.showmodal = true;
  //   }
  // },
  methods: {
    ...mapActions(["updateregistration", "putEmailaddressregistration"]),
    submit(input) {
      if (input == "emailaddress") {
        this.putEmailaddressregistration({
          step: input,
          registrationdata: {
            emailaddress: this.registrationClientData.emailaddress,
          },
        });
      } else if (
        input == "emailaddressVerification"
        // && !this.$v.registrationClientData.verificationToken.$invalid
      ) {
        this.putEmailaddressregistration({
          step: input,
          registrationdata: {
            emailaddress: this.registrationClientData.emailaddress,
            verificationToken: this.registrationClientData.verificationToken,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
///////////////////////////// slide up animation /////////////////////////////

.slideUp-enter-active {
  animation: slide-in-up 300ms ease-out forwards;
}

.slideUp-leave-active {
  animation: slide-out-up 300ms ease-out backwards;
}

@keyframes slide-in-up {
  from {
    transform: translateY(-40px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out-up {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(40px);
    opacity: 0;
  }
}

///////////////////////////// slide down animation /////////////////////////////

.slideDown-enter-active {
  animation: slide-in-down 300ms ease-out forwards;
}

.slideDown-leave-active {
  animation: slide-out-down 300ms ease-out backwards;
}

@keyframes slide-in-down {
  from {
    transform: translateY(40px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out-down {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-40px);
    opacity: 0;
  }
}
</style>
