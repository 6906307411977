/* eslint-disable camelcase */
import axios from "axios"
import { DialogProgrammatic as Dialog } from 'buefy'
// import router from '../router'

const url = "/realm/user/transactions"

export default {
    state: {
        plaidaccesstoken: null,
        transactionResponse: null,
        error_code: null
    },
    getters: {
        plaidaccesstoken(state) {
            return state.plaidaccesstoken
        },
        transactionResponse(state) {
            return state.transactionResponse
        }
    },
    mutations: {
        savetransactionResponse(state, payload) {
            state.transactionResponse = payload
        }
    },
    actions: {

        async requestplaidaccesstoken(context) {
            // console.log('hoi')
            // console.log(Plaid)
            // const registrationToken = state.registrationToken
            // rootState.errormessage = null

            const fetchLinkToken = async () => {
                try {
                    const response = await axios.post(url + "/create_link_token", null)
                    const link_token = response.data.data.link_token
                    return link_token

                } catch (error) {
                    // rootState.errormessage = error.response.data.message
                }
            }

            // const fetchLinkToken = async () => {
            //   const response = await axios.post('api/v1/transactions/create_link_token',
            // { registrationToken: registrationToken })
            //   // console.log(response)
            //   const link_token = response.data.data.link_token
            //   return link_token
            // }

            const configs = {
                // Required, fetch a link token from your server and pass it
                // back to your app to initialize Link.
                token: await fetchLinkToken(),
                onLoad: function () {
                    // Optional, called when Link loads
                },
                onSuccess: async function (public_token, metadata) {
                    // Send the public_token to your app server.
                    // The metadata object contains info about the institution the
                    // user selected and the account ID or IDs, if the
                    // Select Account view is enabled.

                    // let accessTokenResponse

                    try {

                        await axios.post(url + "/get_access_token", {

                            data: {
                                public_token: public_token,
                                metadata: metadata
                            }
                        })
                    } catch (error) {
                        // rootState.errormessage = error.response.data.message
                    }

                    let transactionResponse

                    try {
                        transactionResponse = await axios.post(url + "/transactions", null, {
                            data: {
                                public_token: public_token,
                                metadata: metadata
                            }
                        })

                        // console.log(transactionResponse.data.data)

                        const productlist = transactionResponse.data.data.advice.filter((product) => product.advice)
                            .map((x) => x.name);

                        context.dispatch("getProductsStatus")

                        Dialog.alert({
                            title: 'Ons advies',
                            message:
                             'Jouw transacties zijn geanalyseerd en op basis van je uitgaven adviseren we ten minste een <b>' + productlist[0] + '.</b> <br> <br> Wil je weten welke nog meer? In het dashboard zijn ze allemaal te zien in de groen omlijnde vakjes!',
                            confirmText: 'Cool!',
                            hasIcon: true,
                            icon: 'money-check',
                        }
                        )
                        
                        // context.commit("savetransactionResponse", transactionResponse.data.data)
                    } catch (error) {
                        // console.log('error', error.response.data.message)
                        // rootState.errormessage = error.response.data.message
                        // window.alert(error.response.message)
                        // console.error(error)
                    }

                    // console.log(transactionResponse)
                },
                onExit: async function (err) {
                    // The user exited the Link flow.
                    if (err != null) {
                        // The user encountered a Plaid API error prior to exiting.
                        if (err.error_code === "INVALID_LINK_TOKEN") {
                            // The link_token expired or the user entered too many
                            // invalid credentials. We want to destroy the old iframe
                            // and reinitialize Plaid Link with a new link_token.
                            handler.destroy()
                            // eslint-disable-next-line
                            handler = Plaid.create({
                                ...configs,
                                token: await fetchLinkToken()
                            })
                        }
                    }
                    // metadata contains information about the institution
                    // that the user selected and the most recent API request IDs.
                    // Storing this information can be helpful for support.
                }

                // onEvent: function (eventName, metadata) {
                //   // Optionally capture Link flow events, streamed through
                //   // this callback as your users connect an Item to Plaid.
                //   // For example:
                //   // eventName = "TRANSITION_VIEW"
                //   // metadata  = {
                //   //   link_session_id: "123-abc",
                //   //   mfa_type:        "questions",
                //   //   timestamp:       "2017-09-14T14:42:19.350Z",
                //   //   view_name:       "MFA",
                //   // }
                // }
            }
            // eslint-disable-next-line
            let handler = Plaid.create(configs)
            handler.open()
        }
    }
}
